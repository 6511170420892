import {URL} from '../VariaveisControle'

export const TOKEN_KEY = "@siscob-Token";
export const ROLE_KEY = "@siscob-Role";
export const NAME_KEY = "@siscob-Name";
export const URL_BASE_SERVER = (window.location.host === 'localhost' ? "http://" : "https://") +URL;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const isAdminAuthenticated = () => localStorage.getItem(ROLE_KEY) === "999";
export const isEmpresaAuthenticated = () => localStorage.getItem(ROLE_KEY) === "3";
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getName = () => localStorage.getItem(NAME_KEY);
export const login = (token, role, name) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(ROLE_KEY, role);
  localStorage.setItem(NAME_KEY, name);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ROLE_KEY);
  localStorage.removeItem(NAME_KEY);
};
