import React, {useState, useEffect}  from 'react';
import TabelaPaginada from '../components/TabelaPaginada';
import { useLocation } from 'react-router-dom';
import api from '../auth/Api';
import { logout } from '../auth/Auth';

function Devedores() {
  let urlId = useLocation().pathname.replace("/app/devedores", "").replace("/", "");
  
  let isNew = false;
  if(urlId==="new") {
    isNew = true;
  }
  urlId = Number(urlId);
  
  let isEditing = false;
  if(urlId) {
    isEditing = true;
  }

  const caminhoAtual = useLocation().pathname;
  let [foiEditado, setFoiEditado] = useState(false);
  let [formularioMontado, setFormularioMontado] = useState(null);
  let [dadosFormulario, setDadosFormulario] = useState({});
  // let dadosApi = null;
  let [dadosBusca, setDadosBusca] = useState(null);

  useEffect(() => {
    if(isEditing) {
      if(!dadosBusca) {
        buscar(urlId);
      } else {
        setFormularioMontado(buildForm());
      }
    } else if(isNew) {
      setFormularioMontado(buildForm());
    }
  },[dadosBusca, dadosFormulario, foiEditado]);

  function buscar() {
    const url = 'devedores/buscar/'+urlId;
    api.get(url)
      .then((response) => processar(response.data))
      .catch((err) => {
        console.log(err);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href='/';
          return;
        }
      });
  }

  function processar(value) {
    // dadosApi = value;
    setDadosBusca(value);
    setFormularioMontado(buildForm());
  }

  function getBindValue(linha, bind) {
    if (linha === null)
      return "";

    let b = (bind || "");
    const nivelDeAcesso = b.split(".");
    let binded = linha;
    nivelDeAcesso.forEach(n => {
      binded = binded[n];
    });
    return binded;
  }

  function buildInlineInput(bind, descricao, required, disabled) {
    const req = required ? "required" : "";
    const des = disabled ? "disabled" : "";
    if(!dadosFormulario[bind] && !foiEditado) {
      dadosFormulario[bind] = (getBindValue(dadosBusca, bind) || "" );
    }
    return (<div className="col form-inline">
      {descricao ? 
        <label htmlFor={bind} className={req}>&nbsp;{descricao}</label>
        : null}
      
      <input type="text" className="form-control" id={bind} disabled={des}
        value={dadosFormulario[`${bind}`] ? dadosFormulario[`${bind}`] : ""} 
        required={req} onChange={(e) => {onChangeField(e, bind, e.target.value)}}/>
    </div>);
  }

  function buildForm() {
    return (
      <div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("cpfcnpj", "CPF/CNPJ", false)}
            <div className="col form-inline"></div>
            <div className="col form-inline"></div>
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("nomerazao", "Nome Razão", false)}
            <div className="col form-inline"></div>
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("nomefantasia", "Nome Fantasia", false)}
            <div className="col form-inline"></div>
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("contato", "Contato", false)}
            <div className="col form-inline"></div>
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("contatodesc", "Contato Desc.", false)}
            <div className="col form-inline"></div>
          </div>
        </div>
      </div>
    );
  }
  
  function onChangeField(e, bind, value) {
    setDadosFormulario({...dadosFormulario, [`${bind}`]:value});
    setFoiEditado(true);
  }

  function salvarFormulario() {
    const dadosEnvio = {...dadosFormulario, "iddevedor":urlId};
    api.put("devedores", dadosEnvio)
      .then((response) => {
        toastSuccessAlert("salvo");
        // setFoiEditado(false);
      }).catch((err) => {
        toastErrorAlert("salvar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }

  function deletarFormulario() {
    api.delete("devedores/"+urlId)
      .then((response) => {
        toastSuccessAlert("deletado");
        window.location.href="/app/devedores";
      })
      .catch((err) => {
        toastErrorAlert("deletar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }
  
  // Toasts success alert
  function toastSuccessAlert(operacao) {
    window.halfmoon.initStickyAlert({
      content: "O registro foi "+operacao+" com sucesso.",
      title: "Empresa",
      alertType: "alert-success",
      fillType: "filled-lm"
    });
  }
  
  // Toasts success alert
  function toastErrorAlert(operacao, mensagem) {
    window.halfmoon.initStickyAlert({
      content: "Não foi possível "+operacao+" o registro.<br><br>"+mensagem,
      title: "Empresa",
      alertType: "alert-error",
      fillType: "filled-lm"
    });
  }

  return (
    <div className="content">
      {isEditing || isNew ?
        <div>
          <h1 className="content-title">Devedor</h1>
          <div className="sticky-alerts"></div>
          <div className="mw-full">
            {formularioMontado}

            <button className="btn btn-primary" type="button" onClick={salvarFormulario}
                      disabled={!foiEditado ? "disabled" : ""}>
              <i className="fas fa-save"></i> Salvar
            </button>
            &nbsp;
            {isEditing ?
              <button className="btn btn-primary" type="button" onClick={deletarFormulario}>
                <i className="fas fa-trash-alt"></i> Deletar
              </button>
              : null
            }
          </div>
        </div>
      :
        <div className="mw-full form-inline">
          <a href="/app/devedores/new" className="btn btn-primary" role="button">Novo</a>
          <TabelaPaginada apiNome="devedores" quantidadePorPagina="10"
            colunas={[
              {nome:"CPF/CNPJ Devedor",   bind:"cpfcnpj", formato: "cpfOuCnpj", url:"/app/devedores/{iddevedor}"},
              {nome:"Nome Devedor",       bind:"nomefantasia"},
              {nome:"Contato",            bind:"contato", formato: "telefone"},
              {nome:"Tel.",               bind:"contato", formato:"phoneIconCopyButton"},
              {nome:"Contato Descrição",  bind:"contatodesc"},
              {nome:"Editar",             bind:"iddevedor", url:"/app/devedores/{iddevedor}", formato:"buttonEdit"}
            ]} />
        </div>
      }
    </div>
  );
}

export default Devedores;