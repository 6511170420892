import React, {useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html
function Editor(props) {
    const [dados, setDados] = useState((props.dados || ''));
    
    var onChangeFunction = () => {};
    if(props.onChange) {
      onChangeFunction = props.onChange.bind(this);
    }

    function changeDados(e) {
      setDados(e);
      if(props.onChange !== undefined) {
        onChangeFunction(e);
      }
    }
    return (
      <div style={{color:'black'}}>
        <CKEditor
          config={ {
            toolbar: {
              items: [
                  'heading', '|',
                  'bold', 'italic', '|',
                  'link', '|',
                  'outdent', 'indent', '|',
                  'bulletedList', 'numberedList', '|',
                  'insertTable', '|',
                  'blockQuote', '|',
                  'undo', 'redo'
              ],
              shouldNotGroupWhenFull: true
            }
          } }
          editor={ ClassicEditor }
          data={dados}
          onReady={ ( props.onReady || (editor => {
              // You can store the "editor" and use when it is needed.
              // console.log( 'Editor is ready to use!', editor );
          })) }
          onChange={ ( event, editor ) => {
              const data = editor.getData();
              if(props.onChange) {
                changeDados(data);
              }
          } }
          onBlur={ ( event, editor ) => {
              //Quando perde o foco
          } }
          onFocus={ ( event, editor ) => {
              //Quando ganha foco
          } }
        />
      </div>
    );
}

export default Editor;