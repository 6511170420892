import React, { useEffect, useState } from 'react';

function Erro(props) {
  const [mostrarErro, setMostrarErro] = useState(props.mostrarErro);
  const [mensagemErro, setMensagemErro] = useState(props.mensagemErro);

  let funcaoOnClose = () => {};

  useEffect(() => {
    if(typeof(props.onClose) === 'function') {
      funcaoOnClose = props.onClose.bind(this);
    }
    setMostrarErro(props.mostrarErro);
    setMensagemErro(props.mensagemErro);
  }, [props.mostrarErro, props.mensagemErro, props.onClose]);

  return ( 
    <div>
      { mostrarErro ?
        <div id="alerta" className="justify-content-center" style={{paddingBottom: '7px'}}>
          <div className="alert alert-danger" role="alert">
            <button className="close" data-dismiss="alert" type="button" aria-label="Close" 
                      onClick={() => {setMostrarErro(false); funcaoOnClose();}}>
              <span aria-hidden="true">&times;</span>
            </button>
            {mensagemErro}
          </div>
        </div>
      : null }
    </div>
  );
}

export default Erro;
