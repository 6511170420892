import axios from "axios";
import { getToken } from "./Auth";
import { URL_BASE_SERVER } from "./Auth";

function createAxis() {
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  // console.log(URL_BASE_SERVER);
  return axios.create({
    baseURL: URL_BASE_SERVER+"/api"
  });
};

const api = createAxis();
// const api = axios.create({
//   baseURL: URL_BASE_SERVER+"/api"
// });

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export default api;
