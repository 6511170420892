import React from 'react';
import api from '../auth/Api';
import FormData from 'form-data';
import { logout } from '../auth/Auth';

function Importacao() {
  function processarRetornoImportacao(response) {
    toastSuccessAlert();
  }
  
  // Toasts success alert
  function toastSuccessAlert() {
    window.halfmoon.initStickyAlert({
      content: "Importação realizada com sucesso.",
      title: "Dívida",
      alertType: "alert-success",
      fillType: "filled-lm"
    });
  }
  
  // Toasts success alert
  function toastFailtAlert(msg) {
    window.halfmoon.initStickyAlert({
      content: "Falha na importação.<br><br>"+msg,
      title: "Dívida",
      alertType: "alert-danger",
      fillType: "filled-lm"
    });
  }

  function importFile() {
    var data = new FormData();
    var fileData = document.querySelector('input[type="file"]').files[0];
    data.append("file", fileData);

    const url = 'dividas/import';
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    
    api.post(url, data, config)
      .then((response) => processarRetornoImportacao(response))
      .catch((err) => {
        if(err.response.status === 403) {
          logout();
          window.location.href='/';
          return;
        }
        toastFailtAlert(err.response.data.error);
      });
  }

  return (
    <div className="content">
      <h1 className="content-title">
      Importação
      </h1>

      <div className="sticky-alerts"></div>
      <form action="/api/dividas/import" method="POST" 
            encType="multipart/form-data"  className="w-400 mw-full">
        <div className="form-group">
          <div className="custom-file">
            <input type="file" id="file" name="file" accept=".csv" />
            <label htmlFor="file">Escolha um arquivo CSV</label>
          </div>
          <br />
          <input className="btn btn-primary" type="button" value="Importar" onClick={importFile} />
        </div>
      </form>
    </div>
  );
}

export default Importacao;