import React from 'react';

const URL_PRD = "siscob.ddns.net";
// const URL_DEV = "localhost";
const URLS_PRD = [URL_PRD, "siscob.durva.pro", "sec.durva.pro", "sec.cobranca.org", "sistema.cobranca.org", "cobranca.org"]

// export let URL = URL_DEV;
export let URL = window.location.host; //window.location.host === 'localhost' ? 'localhost' : 'siscob.durva.pro';

export const blockInProduction = (comp) => {
    if(URL === URL_PRD || URLS_PRD.includes(URL))
        return (<h1>Em desenvolvimento</h1>);
    return comp;
}