import React, {useState, useEffect} from 'react';
import api from '../../auth/Api';
import { logout } from '../../auth/Auth';
import { formatoStatusDivida } from '../../components/Util';
import TabelaPaginada from '../../components/TabelaPaginada';

function AcessoEmpresasClientes(props) {
  let [cobranca, setCobranca] = useState('');
  let [atraso, setAtraso] = useState('');
  let [acordo, setAcordo] = useState('');
  let [liquidado, setLiquidado] = useState('');

  api.get("acesso/empresa/painel")
  .then((response) => {
    const dados = response.data;
    setCobranca(dados.cobranca);
    setAtraso(dados.atraso);
    setAcordo(dados.acordo);
    setLiquidado(dados.liquidado);
  }).catch((err) => {
    // console.log(err);
    if(err.response.status === 400) {
      
    } else if(err.response.status === 403) {
      logout();
      window.location.href='/';
      return;
    }
  });


  const estiloIcone = {
    marginLeft: '-10px',
    marginTop: '15px'
  };

  const estiloTexto = {
    marginLeft: '-30px', 
    fontWeight: 'bold', 
    textAlign: 'center',
    fontSize: '14px'
  };

  const estiloQuantidade = {fontSize: '30px'};

  const colStyleForm = {paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold'};

  const colorFalse = '#4F4F4F';
  const ctNegociando = '#4169E1';
  let [fNegociando, setFNegociando] = useState(true);
  let [colorNegociando, setColorNegociando] = useState(ctNegociando);

  const ctAtraso = '#CD0000';
  let [fAtraso, setFAtraso] = useState(true);
  let [colorAtraso, setColorAtraso] = useState(ctAtraso);

  const ctAcordo = '#008B45';
  let [fAcordo, setFAcordo] = useState(true);
  let [colorAcordo, setColorAcordo] = useState(ctAcordo);

  const ctLiquidado = '#228B22';
  let [fLiquidado, setFLiquidado] = useState(true);
  let [colorLiquidado, setColorLiquidado] = useState(ctLiquidado);

  function filtroNegociando() {
    setFNegociando(!fNegociando);
    if(!fNegociando) 
      setColorNegociando(ctNegociando);
    else
      setColorNegociando(colorFalse);
  }
  function filtroAtraso() {
    setFAtraso(!fAtraso);
    if(!fAtraso)
      setColorAtraso(ctAtraso);
    else
      setColorAtraso(colorFalse);
  }
  function filtroAcordo() {
    setFAcordo(!fAcordo);
    if(!fAcordo)
      setColorAcordo(ctAcordo);
    else
      setColorAcordo(colorFalse);
  }
  function filtroLiquidado() {
    setFLiquidado(!fLiquidado);
    if(!fLiquidado)
      setColorLiquidado(ctLiquidado);
    else
      setColorLiquidado(colorFalse);
  }

  let [q, setQ] = useState('');
  let [filtro, setFiltro] = useState(montarFiltroTabela(true, true, true, true, ''));

  function montarFiltroTabela(negociando, atraso, acordo, liquidado, q) {
    return "negociando="+negociando+"&atraso="+atraso+
      "&acordo="+acordo+"&liquidado="+liquidado+"&q="+q;
  }

  useEffect(() => {
    setFiltro(montarFiltroTabela(fNegociando, fAtraso, fAcordo, fLiquidado, q));
  },[fNegociando, fAtraso, fAcordo, fLiquidado, q]);

  return (
    <div className="content">
      <h1 className="content-title">
        Painel
      </h1>
      <div className="container-fluid">
        <div className="row row-eq-spacing">

          <div className="col">
            <div className="row card" style={{color: 'white', 
                  backgroundColor: colorNegociando, cursor: 'pointer'}} 
                data-toggle="tooltip" data-title="Ativar/Desativar filtro por status 'Processo de cobrança'"
                onClick={filtroNegociando}>
              <div className="col" style={estiloIcone}>
                <i className="fas fa-file-invoice fa-4x"></i>
              </div>
              <div className="col" style={estiloTexto}>
                Processo de<br/>cobrança<br/>
                <span style={estiloQuantidade}>{cobranca}</span>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="row card" style={{color: 'white', 
                  backgroundColor: colorAtraso, cursor: 'pointer'}} 
                data-toggle="tooltip" data-title="Ativar/Desativar filtro por status 'Atraso'"
                onClick={filtroAtraso}>
              <div className="col" style={estiloIcone}>
                <i className="fas fa-minus-square fa-4x"></i>
              </div>
              <div className="col" style={estiloTexto}>
                <br/>Atraso <br/>
                <span style={estiloQuantidade}>{atraso}</span>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="row card" style={{color: 'white', 
                backgroundColor: colorAcordo, cursor: 'pointer'}} 
                data-toggle="tooltip" data-title="Ativar/Desativar filtro por status 'Acordo'"
                onClick={filtroAcordo}>
              <div className="col" style={estiloIcone}>
                <i className="fas fa-thumbs-up fa-4x"></i>
              </div>
              <div className="col" style={estiloTexto}>
                <br/>Acordo <br/>
                <span style={estiloQuantidade}>{acordo}</span>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="row card" style={{color: 'white', 
                backgroundColor: colorLiquidado, cursor: 'pointer'}} 
                data-toggle="tooltip" data-title="Ativar/Desativar filtro por status 'Liquidado'"
                onClick={filtroLiquidado}>
              <div className="col" style={estiloIcone}>
                <i className="fas fa-clipboard-check fa-4x"></i>
              </div>
              <div className="col" style={estiloTexto}>
                <br/>Liquidado <br/>
                <span style={estiloQuantidade}>{liquidado}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="alert alert-secondary" role="alert">
              <button className="close" data-dismiss="alert" type="button" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <center>Clique nos botões acima para alterar o filtro por 'Status' da listagem abaixo.</center>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div>
        <div className="row">
          <div className="col form-inline" style={colStyleForm}>
            <label htmlFor="cpfcnpj-devedor">CPF/CNPJ Devedor: </label>
            <input type="text" className="form-control" id="cpfcnpj-devedor" value={q}
              placeholder="CPF/CNPJ Devedor" onChange={(e)=>{setQ(e.target.value)}} />
          </div>
        </div>
        <div className="row">
          <TabelaPaginada 
            apiNome="acesso/empresa/cobrancas" 
            quantidadePorPagina="10" filtro={filtro}
            colunas={[
              {nome:"Cód. Referência", bind:"idloja"},
              {nome:"CPF/CNPJ Devedor", bind:"devedor.cpfcnpj", formato:"cpfOuCnpj"},
              {nome:"Nome Devedor", bind:"devedor.nomerazao"},
              {nome:"Data dívida", bind:"datadivida"},
              {nome:"Valor dívida", bind:"valorcapital", formato:"dinheiro"},
              {nome:"Status", bind:"statusDivida",formato: formatoStatusDivida }
            ]} />
        </div>
      </div>

    </div>
  );
}

export default AcessoEmpresasClientes;