import React from 'react';
import moment from "moment";

export const formatoStatusDivida = e => {
    if(e === "Processo de cobrança" || e === "Negociando") {
      return "badge badge-primary";
    }
    if(e === "Deslocalizado" || e === "Recado Formal") {
      return "badge badge-secondary";
    }
    if(e === "Recusa" || e === "Tentativa" || e === "Sem condições") {
      return "badge badge-danger";
    } 
    if(e === "Acordo" || e === "Liquidado") {
      return "badge badge-success";
    }
    return "badge badge-default";
};

export const formatoStatusAcordo = e => {
    if(e === "Aprovado") {
      return "badge badge-primary";
    }
    if(e === "Concluido") {
      return "badge badge-success";
    }
    if(e === "Cancelado") {
      return "badge badge-danger";
    } 
    return "badge badge-default";
};

export const createBadge = e => {
  return (<span className={formatoStatusDivida(e)}>{e}</span>);
}

export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
}

export const cpfOuCnpj = value => {
  const apenasNumero = value.replace(/\D/g, '');
  if (apenasNumero.length <= 11) {
    return cpfMask(value);
  } else {
    return cnpjMask(value);
  }
}

export const formatarData = (value, f) => {
  const valorData = moment(value).locale("br").format(f);
  return valorData === 'Invalid date' ? 'Não informada' : valorData;
}

//Convert to CSV
function ConvertToCSV(objArray) {
  var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          if (line !== '') line += ','

          line += array[i][index];
      }
      str += line + '\r\n';
  }
  return str;
}

export function downloadCSV(data) {
  let csv = ConvertToCSV(JSON.stringify(data));
    
  var hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = 'data.csv';
  hiddenElement.click();
}

export function getYearsDiff(date1, date2) {
  let years = new Date(date1).getFullYear() - new Date(date2).getFullYear();
  let month = new Date(date1).getMonth() - new Date(date2).getMonth();
  let dateDiff = new Date(date1).getDay() - new Date(date2).getDay();
  if (dateDiff < 0) {
      month -= 1;
  }
  if (month < 0) {
      years -= 1;
  }
  return years;
}

export function formatDate(date) {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // month is zero-based
  let dd = date.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

  return yyyy+"-"+mm+"-"+dd;
}