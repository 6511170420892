import React, {useState, useEffect} from 'react';
import TabelaPaginada from '../components/TabelaPaginada';
import { formatoStatusDivida } from '../components/Util';
import { useLocation } from 'react-router';
import Editor from '../components/Editor';
import api from '../auth/Api';
import { logout } from '../auth/Auth';
import { cpfOuCnpj, createBadge, formatarData } from '../components/Util';
import DateSelect from '../components/DateSelect';
import moment from "moment";
import Erro from '../components/Erro';

let gEditor = 'textEditor';

function BuscarDivida(props) {
  const caminhoAtual = useLocation().pathname;

  let qInicial = new URLSearchParams(useLocation().search).get("q");
  if(qInicial === null) {
    qInicial = "";
  }
  let [valorBusca, setValorBusca] = useState(qInicial);
  let [filtro, setFiltro] = useState(montarFiltro(qInicial));

  function changeFiltro(value) {
    setValorBusca(value);
    setFiltro(montarFiltro(value));
  }

  function montarFiltro(data) {
    return "q="+data;
  }

  //Identifica se é uma atendimento de divida
  // let [empresa, setEmpresa] = useState('');
  let [devedor, setDevedor] = useState('');
  let [documento, setDocumento] = useState('');
  // let [datadivida, setDatadivida] = useState('');
  let [descrDivida, setDescrDivida] = useState('');
  // let [valorcapital, setValorcapital] = useState('');
  // let [status, setStatus] = useState('');
  let [cobrancas, setCobrancas] = useState('');
  let [cbAgendamento, setCbAgendamento] = useState(true);

  function carregarCobrancas(cobrancas) {
    const sortedArray  = cobrancas.sort((a,b) => 
      new moment(a.dataCobranca).format('YYYYMMDD') - new moment(b.dataCobranca).format('YYYYMMDD'));
    return (
      <div>
      {
        sortedArray.reverse().map((cobranca, i) => {
          return (
            <div className="card" key={i} style={{margin: '1.5rem 0rem 1.5rem 0rem'}}>
              <h2 className="content-title">
                Registro de contato
              </h2>
              <div className="form-row row-eq-spacing-sm">
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Data da cobrança</label><br/>{formatarData(cobranca.dataCobranca, 'DD/MM/yyyy')}
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Data de retorno</label><br/>{formatarData(cobranca.dataHoraAgendamento, 'DD/MM/yyyy')}
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Usuário</label><br/>{cobranca.usuario ? cobranca.usuario.usuario : ""}
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Status</label><br/>{createBadge(cobranca.status)}
                </div>
              </div>
              <br/>
              <section dangerouslySetInnerHTML={{ __html: cobranca.descricaoDoContato}} />
            </div>
          );
        })
      }
      </div>
    );
  }

  function processarDivida(divida) {
    // setEmpresa(divida.empresa.razaosocial);
    setDevedor(divida.devedor.nomerazao);
    setDocumento(cpfOuCnpj(divida.devedor.cpfcnpj));
    // setDatadivida(divida.datadivida);
    setDescrDivida(divida.observacao);
    // const vlrCap = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.valorcapital);
    // setValorcapital(vlrCap);
    // setStatus(createBadge(divida.statusDivida));
    setCobrancas(carregarCobrancas(divida.cobrancas));
    setValorTotalParcelas(divida.valorcapital);
    setValorCapital(divida.valorcapital);
    setPercentualEmpresa(parseFloat(divida.percentualEmpresa));
  }

  function buscarDivida(id) {
    const url = 'dividas/buscar/'+id;
    api.get(url)
      .then((response) => processarDivida(response.data))
      .catch((err) => {
        console.log(err);
        if(err.response.status === 400) {
        } else if(err.response.status === 403) {
          logout();
          window.location.href='/';
          return;
        }
      });
  }

  let idDivida = caminhoAtual.replace("/app/buscar-divida/", '');
  idDivida = Number(idDivida);
  useEffect(() => {
    if(idDivida && devedor === "") {
      buscarDivida(idDivida);
    }

    if (statusNovo === "Acordo") {
      atualizaParcela();
    }
  });

  let [dataNovo, setDataNovo] = useState(moment().format((props.formato || "YYYY-MM-DD" )));
  let [horaAgendamento, setHoraAgendamento] = useState(moment().format((props.formato || "hh:mm" )));
  let [statusNovo, setStatusNovo] = useState('Processo de cobrança');
  let [conteudoEditor, setConteudoEditor] = useState('');

  // Acordo
  let [valorCapital, setValorCapital] = useState(0);
  let [cbAcordo, setCbAcordo] = useState(false);
  let [valorTotalParcelas, setValorTotalParcelas] = useState(0);
  let [totalDeParcelas, setTotalDeParcelas] = useState(1);
  let [valorDaParcela, setValorDaParcela] = useState(0);
  let [entradaAcordo, setEntradaAcordo] = useState(0);
  let [dataEntradaAcordo, setDataEntradaAcordo] = useState('');
  let [percentualEmpresa, setPercentualEmpresa] = useState(0);
  let [valorTotalAcordo, setValorTotalAcordo] = useState(0);
  let [dataPrimeiraParcela, setDataPrimeiraParcela] = useState('');
  let [repasse, setRepasse] = useState(0);
  let [repassePorParcela, setRepassePorParcela] = useState(0);

  function atualizaParcela() {
    const percentualRepasse = (parseFloat(percentualEmpresa) / 100)
    setValorDaParcela(valorTotalParcelas/totalDeParcelas)

    const totAcordo = parseFloat(valorTotalParcelas) + parseFloat(entradaAcordo)
    setValorTotalAcordo(totAcordo)

    const percentualComissao = valorCapital * percentualRepasse / totAcordo;
    setRepasse(valorCapital * percentualRepasse)
    setRepassePorParcela(valorDaParcela * percentualComissao)
  }

  // Acordo
  
  function changeDataNova(value) {
    console.log(value);
    setDataNovo(value);
  }

  function salvarNovoRegistroDeContato(e) {
    // console.log("dataCobranca: "+moment().toISOString());
    // console.log("dataHoraAgendamento: "+dataNovo + "T" + horaAgendamento+".000Z");
    setErroEditor(false);
    if(conteudoEditor.length <= 15+7) {
      setErroEditor(true);
      setMensagemErroEditor("Para registrar uma cobrança é preciso ter mais de 15 caracteres.");
      return;
    }

    const cobranca = {
      dataCobranca: moment().toISOString(),//format("YYYY-MM-DD"),
      dataHoraAgendamento: cbAgendamento ? dataNovo + "T" + horaAgendamento+".000Z" : null,
      status: statusNovo,
      descricaoDoContato: conteudoEditor,
      iddivida: idDivida
    };

    if (cbAcordo) {
      cobranca.acordo = {
        totalDeParcelas: totalDeParcelas,
        valorDaParcela: valorDaParcela,
        dataPrimeiraParcela: dataPrimeiraParcela,

        valorEntrada: entradaAcordo,
        dataEntrada: dataEntradaAcordo
      }
    }

    api.put("cobrancas", cobranca)
      .then((response) => {
        buscarDivida(idDivida);

        gEditor.setData('');
        setConteudoEditor('');
        toastSuccessAlert();
      }).catch((err) => {
        // console.log(err);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }
  
  // Toasts success alert
  function toastSuccessAlert() {
    window.halfmoon.initStickyAlert({
      content: "O registro de cobrança foi realizado com sucesso.",
      title: "Cobrança",
      alertType: "alert-success",
      fillType: "filled-lm"
    });
  }

  function setHabilitaAgendamento() {
    setCbAgendamento(!cbAgendamento);
  }

  function setHabilitaAcordo() {
    setCbAcordo(!cbAcordo);
  }

  let [erroEditor, setErroEditor] = useState(false);
  let [mensagemErroEditor, setMensagemErroEditor] = useState(false);

  return (
    <div className="content">
      {idDivida ? 
        <div>
          <div className="sticky-alerts"></div>

          <h1 className="content-title">
            Todas as dívidas de <b>{devedor}</b>
          </h1>
          <TabelaPaginada apiNome="dividas/buscar" filtro={"q="+documento}
              semPaginacao={true} selecionado={idDivida} colunas={[
                {nome: "Sel.", bind:"iddivida", formato:"selecaoSimples", url:"/app/buscar-divida/{iddivida}"},
                // {nome:"CNPJ Empresa", bind:"empresa.cnpj", formato:"cpfOuCnpj"},
                {nome:"Nome Empresa", bind:"empresa.nomefantasia"},
                {nome:"Nome Devedor", bind:"devedor.nomerazao"},
                {nome:"Documento", bind:"devedor.cpfcnpj", formato:"cpfOuCnpj"},
                {nome:"Data da dívida", bind:"datadivida", formato:"dateUsToBr"},
                {nome:"Tel.", bind:"devedor.contato", formato:"phoneIconCopyButton"},
                {nome:"Descr.", bind:"devedor.contatodesc", formato:"iconPersonalizedCopy", icone:"fas fa-file-signature"},
                {nome:"Valor dívida", bind:"valorcapital", formato:"dinheiro"},
                {nome:"Pend. Acordo", bind:"valorPendenteAcordo", formato:"dinheiro"},
                {nome:"Status", bind:"statusDivida", url:"/app/buscar-divida/{iddivida}", formato: formatoStatusDivida }
              ]} />

          <div className="card" style={{margin: '1.5rem 0rem 1.5rem 0rem'}}>
            { descrDivida ?
            <div className="form-row row-eq-spacing-sm">
              <div className="col-sm">
                <div className="content">
                  <h2 className="content-title">
                    Observação: <span style={{color: 'red'}}>{descrDivida}</span>
                  </h2>
                </div>
              </div>
            </div>
            : null }
            <div className="form-row row-eq-spacing-sm">
              <div className="col-sm">
                <label htmlFor="status_novo" style={{fontWeight: 'bold'}}>Status</label><br/>
                <select className="form-control" id="status_novo" defaultValue="Processo de cobrança"
                    onChange={(e) => {setStatusNovo(e.target.value)}} >
                  <option value="Processo de cobrança">Processo de cobrança</option>
                  <option value="Negociando">Negociando</option>
                  <option value="Tentativa">Tentativa</option>
                  <option value="Recado Formal">Recado Formal</option>
                  <option value="Recado com Terceiro">Recado com Terceiro</option>
                  <option value="Recusa">Recusa</option>
                  <option value="Sem condições">Sem condições</option>
                  <option value="Deslocalizado">Deslocalizado</option>
                  <option value="Acordo">Acordo</option>
                  <option value="Liquidado">Liquidado</option>
                  <option value="Falecido">Falecido</option>
                  <option value="Não cobrar">Não cobrar</option>
                  <option value="Atraso">Atraso</option>
                </select>
              </div>
              <div className="col-sm">
                <label style={{fontWeight: 'bold'}}>Opção</label><br/>
                  <div className="custom-switch">
                    <input type="checkbox" id="switch-2" value={cbAgendamento} defaultChecked={cbAgendamento}
                      onChange={(e) => {setHabilitaAgendamento(e.target)}} /> 
                    <label htmlFor="switch-2">Habilita agendamento</label>
                  </div>
              </div>
            </div>
            <div className="form-row row-eq-spacing-sm">
              <div className="col-sm">
                {cbAgendamento ? <label style={{fontWeight: 'bold'}}>Data de agendamento</label> : <div/> }<br/>
                {cbAgendamento ? <DateSelect value={dataNovo} onChange={changeDataNova} /> : <div/> }
              </div>
              <div className="col-sm">
                {cbAgendamento ? <label style={{fontWeight: 'bold'}}>Hora da ocorrência</label> : <div/> } <br/>
                {cbAgendamento ? 
                <input type="time" className="form-control" 
                  value={horaAgendamento} onChange={(e) => {setHoraAgendamento(e.target.value)}} />
                  : <div/> }
              </div>
            </div> 

            <Erro mostrarErro={erroEditor} mensagemErro={mensagemErroEditor} onClose={()=>{setErroEditor(!erroEditor)}} />
            <Editor onChange={setConteudoEditor} onReady={(v) => {gEditor = v}} /><br/>
            
            { statusNovo === "Acordo" ?
            <div>
              {cbAcordo ? <div className="form-row row-eq-spacing-sm" hidden>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Valor de entrada</label><br/>
                  <input type="value" className="form-control" 
                    value={entradaAcordo} onChange={(e) => {setEntradaAcordo(e.target.value)}} />
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Entrada em</label><br/>
                  <DateSelect value={dataEntradaAcordo} onChange={setDataEntradaAcordo} />
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>% empresa</label><br/>
                  <input type="value" className="form-control" value={percentualEmpresa} disabled/>
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Total do Acordo</label><br/>
                  <input type="value" className="form-control" value={valorTotalAcordo} disabled/>
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Repasse Total</label><br/>
                  <input type="value" className="form-control" value={repasse} disabled/>
                </div>
              </div> : null }
              { cbAcordo ? <div className="form-row row-eq-spacing-sm" hidden>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>1º parcela</label><br/>
                  <DateSelect value={dataPrimeiraParcela} onChange={setDataPrimeiraParcela} />
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Total de parcelas</label><br/>
                  <input type="value" className="form-control" 
                    value={totalDeParcelas} onChange={(e) => {setTotalDeParcelas(e.target.value)}} />
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Total das Parcelas</label><br/>
                  <input type="value" className="form-control" 
                    value={valorTotalParcelas} onChange={(e) => {setValorTotalParcelas(e.target.value)}} />
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Valor da Parcela</label><br/>
                  <input type="value" className="form-control" value={valorDaParcela} disabled/>
                </div>
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Repasse por parcela</label><br/>
                  <input type="value" className="form-control" value={repassePorParcela} disabled/>
                </div>
              </div> : null }
            </div>
            : null }
            
            { statusNovo === "Acordo" ? <div className="float-left d-inline-block">
                <div className="col-sm">
                  <label style={{fontWeight: 'bold'}}>Opção</label><br/>
                    <div className="custom-switch">
                      <input type="checkbox" id="switch-3" value={cbAcordo} defaultChecked={cbAcordo}
                        onChange={(e) => {setHabilitaAcordo(e.target)}} /> 
                      <label htmlFor="switch-3">Lançar acordo</label>
                    </div>
                </div>
              </div> : null }
            <div className="float-right d-inline-block">
              <button className="btn btn-primary" type="button" onClick={salvarNovoRegistroDeContato}>Salvar</button>
            </div>
            <br/>
          </div>
          {cobrancas}
        </div>
        : <div>
            <h1 className="content-title">
            Buscar dívida
            </h1>
            <form className="mw-full form-inline">
              <div className="col form-inline">
                <label htmlFor="cnpj-empresa" style={{fontWeight: 'bold'}}>Devedor: </label>
                <input type="text" className="form-control" id="cnpj-empresa" value={valorBusca}
                  placeholder="CPF/CNPJ ou Nome" onChange={(e) => {changeFiltro(e.target.value)}} />
              </div>
              <div className="col form-inline"></div>
              <div className="col form-inline"></div>
            </form>
            <br />
            <TabelaPaginada apiNome="dividas/buscar" quantidadePorPagina="10" filtro={filtro}
              colunas={[
                {nome:"CNPJ Empresa", bind:"empresa.cnpj", formato:"cpfOuCnpj"},
                {nome:"Nome Empresa", bind:"empresa.nomefantasia"}, //, url:"/app/empresas/{empresa.idempresa}"
                {nome:"CPF/CNPJ Devedor", bind:"devedor.cpfcnpj", formato:"cpfOuCnpj"},
                {nome:"Nome Devedor", bind:"devedor.nomerazao"}, //, url:"/app/devedores/{devedor.iddevedor}"
                {nome:"Data dívida", bind:"datadivida", formato:"dateUsToBr"},
                {nome:"Tel.", bind:"devedor.contato", formato:"phoneIconCopyButton"},
                {nome:"Descr.", bind:"devedor.contatodesc", formato:"iconPersonalizedCopy", icone:"fas fa-file-signature"},
                {nome:"Valor dívida", bind:"valorcapital", formato:"dinheiro"},
                {nome:"Status", bind:"statusDivida", url:"/app/buscar-divida/{iddivida}", formato: formatoStatusDivida }
              ]} />
          </div>
      }
    </div>
  );
}

export default BuscarDivida;