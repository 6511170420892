import React, {useState, useEffect} from 'react';
import TabelaPaginada from '../components/TabelaPaginada';
import { useLocation } from 'react-router';
import api from '../auth/Api';
import { logout } from '../auth/Auth';

function Empresas() {
  let urlId = useLocation().pathname.replace("/app/empresas", "").replace("/", "");
  
  let isNew = false;
  if(urlId==="new") {
    isNew = true;
  }
  urlId = Number(urlId);
  
  let isEditing = false;
  if(urlId) {
    isEditing = true;
  }

  const caminhoAtual = useLocation().pathname;
  let [foiEditado, setFoiEditado] = useState(false);
  let [formularioMontado, setFormularioMontado] = useState(null);
  let [dadosFormulario, setDadosFormulario] = useState({});
  // let dadosApi = null;
  let [dadosBusca, setDadosBusca] = useState(null);
  let [senhaGerada, setSenhaGerada] = useState(null);

  //Filtro
  let [filtroTabela, setFiltroTabela] = useState('');
  let [valorFiltro, setValorFiltro] = useState('');

  useEffect(() => {
    if(isEditing) {
      if(!dadosBusca) {
        buscar(urlId);
      } else {
        setFormularioMontado(buildForm());
      }
    } else if(isNew) {
      setFormularioMontado(buildForm());
    } else {
      montarFiltroTabela();
    }
  },[valorFiltro, dadosBusca, dadosFormulario, foiEditado, senhaGerada]);

  function montarFiltroTabela() {
    const filtro = valorFiltro.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    setFiltroTabela("q="+(filtro || ""));
  }

  function buscar() {
    const url = 'empresas/'+urlId;
    api.get(url)
      .then((response) => processar(response.data))
      .catch((err) => {
        console.log(err);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href='/';
          return;
        }
      });
  }

  function processar(value) {
    // dadosApi = value;
    setDadosBusca(value);
    setFormularioMontado(buildForm());
  }

  function getBindValue(linha, bind) {
    if (linha === null)
      return "";

    let b = (bind || "");
    const nivelDeAcesso = b.split(".");
    let binded = linha;
    nivelDeAcesso.forEach(n => {
      binded = binded[n];
    });
    return binded;
  }

  function buildInlineActive(bind, descricao, required, disabled) {
    const req = required ? "required" : "";
    const des = disabled ? "disabled" : "";
    if(!dadosFormulario[bind] && !foiEditado) {
      dadosFormulario[bind] = (getBindValue(dadosBusca, bind) || "" );
    }
    return (<div className="col form-inline">
      {descricao ? 
        <label htmlFor={bind} className={req}>&nbsp;{descricao}</label>
        : null}

      <select className="form-control" id={bind} disabled={des} 
          value={dadosFormulario[`${bind}`] ? dadosFormulario[`${bind}`] : ""} 
          required={req} onChange={(e) => {onChangeField(e, bind, e.target.value)}} >
        <option value="INACTIVE">Inativo</option>
        <option value="ACTIVE">Ativo</option>
      </select>
    </div>);
  }

  function buildInlineInput(bind, descricao, required) {
    const req = required ? "required" : "";
    if(!dadosFormulario[bind] && !foiEditado) {
      dadosFormulario[bind] = (getBindValue(dadosBusca, bind) || "" );
    }
    return (<div className="col form-inline">
      {descricao ? 
        <label htmlFor={bind} className={req}>&nbsp;{descricao}</label>
        : null}
      
      <input type="text" className="form-control" id={bind}
        value={dadosFormulario[`${bind}`] ? dadosFormulario[`${bind}`] : ""} 
        required={req} onChange={(e) => {onChangeField(e, bind, e.target.value)}} />
    </div>);
  }

  function buildForm() {
    return (
      <div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            <span style={{fontSize: '30px'}}>Cadastro</span>
          </div>
          <div className="col form-inline">
            <div>
              <span style={{fontSize: '30px'}}>Precificação</span>
              <br/>
              <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;De - Até&nbsp;&nbsp;&nbsp;</b> Faixa de atraso (Dias)
            </div>
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("cnpj", "CNPJ", true)}
            {buildInlineActive("active", "Status", true)}
            {/* <div className="col form-inline"></div> */}
          </div>
          <div className="col form-inline">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              0 - 365&nbsp;&nbsp;&nbsp;</span>
            {buildInlineInput("ateUm")}
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("razaosocial", "Razão Socal", true)}
          </div>
          <div className="col form-inline">
            <span>&nbsp;&nbsp;&nbsp;366 - 730&nbsp;&nbsp;&nbsp;</span>
            {buildInlineInput("ateDois")}
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("nomefantasia", "Nome Fantasia", true)}
          </div>
          <div className="col form-inline">
            <span>731 - 1095&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {buildInlineInput("ateTres")}
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("endereco", "Endereço")}
          </div>
          <div className="col form-inline">
            <span>1096 - 1460&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {buildInlineInput("ateQuatro")}
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("cidade", "Cidade")}
            {buildInlineInput("cep", "CEP")}
          </div>
          <div className="col form-inline">
            <span>1461 - 1825&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {buildInlineInput("ateCinco")}
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("banco", "Banco")}
            {buildInlineInput("agencia", "Agência")}
            {buildInlineInput("conta", "Conta")}
          </div>
          <div className="col form-inline">
            <span> &gt;= 1826&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {buildInlineInput("ateN")}
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("observacao", "Observação")}
          </div>
          <div className="col form-inline">
            {buildInlineInput("pix", "Pix")}
          </div>
        </div>
      </div>
    );
  }

  function onChangeField(e, bind, value) {
    setDadosFormulario({...dadosFormulario, [`${bind}`]:value});
    setFoiEditado(true);
  }

  function salvarFormulario() {
    const dadosEnvio = {...dadosFormulario, "idempresa":urlId};
    api.put("empresas", dadosEnvio)
      .then((response) => {
        toastSuccessAlert("salvo");
        // setFoiEditado(false);
      }).catch((err) => {
        toastErrorAlert("salvar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }

  function deletarFormulario() {
    api.delete("empresas/"+urlId)
      .then((response) => {
        toastSuccessAlert("deletado");
        window.location.href="/app/empresas";
      })
      .catch((err) => {
        toastErrorAlert("deletar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }

  function gerarSenhaEmpresarial() {
    api.post("empresas/generate-password/"+urlId)
      .then((response) => {
        setSenhaGerada(response.data.senha);
      })
      .catch((err) => {
        toastErrorAlert("gerar senha para ");
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }
  
  // Toasts success alert
  function toastSuccessAlert(operacao) {
    window.halfmoon.initStickyAlert({
      content: "O registro foi "+operacao+" com sucesso.",
      title: "Empresa",
      alertType: "alert-success",
      fillType: "filled-lm"
    });
  }
  
  // Toasts success alert
  function toastErrorAlert(operacao, mensagem) {
    window.halfmoon.initStickyAlert({
      content: "Não foi possível "+operacao+" o registro.<br><br>"+mensagem,
      title: "Empresa",
      alertType: "alert-error",
      fillType: "filled-lm"
    });
  }
  
  function copyToClipboard(valor) {
    navigator.clipboard.writeText(valor);
    setSenhaGerada(null);
  }

  const colStyleForm = {paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold'};

  return (
    <div className="content">
      {isEditing || isNew ?
        <div>
          <h1 className="content-title">Empresa</h1>
          <div className="sticky-alerts"></div>
          <div className="mw-full">
            {formularioMontado}


            <div className="row">
              <div className="col">
                <button className="btn btn-primary" type="button" onClick={salvarFormulario}
                          disabled={!foiEditado ? "disabled" : ""}>
                  <i className="fas fa-save"></i> Salvar
                </button>
                &nbsp;
                {isEditing ?
                  <button className="btn btn-primary" type="button" onClick={deletarFormulario}>
                    <i className="fas fa-trash-alt"></i> Deletar
                  </button>
                  : null
                }
                &nbsp;
                {isEditing ?
                  <button className="btn btn-primary" type="button" onClick={gerarSenhaEmpresarial}>
                    <i className="fas fa-key"></i> Gerar senha de acesso empresarial
                  </button>
                  : null
                }
              </div>
              {
                senhaGerada ?
                  <div className="col">
                    <div className="alert alert-secondary" role="alert">
                      <button className="close" data-dismiss="alert" type="button" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      A senha gerada é '{senhaGerada}'&nbsp;
                      <button className="btn" onClick={() => {copyToClipboard(senhaGerada)}}>
                        <span className="fas fa-copy"></span>
                      </button>
                    </div>
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
      :
        <div className="mw-full">
          <div className="form-inline">
            <div className="col" style={colStyleForm}>
              <label htmlFor="filtro-empresa">Filtro: </label>
              <input type="text" className="form-control" id="filtro-empresa"
                placeholder="CNPJ Empresa ou Nome" onChange={(e)=>{setValorFiltro(e.target.value)}} />
            </div>
            <div>
              <br/>
              <a href="/app/empresas/new" className="btn btn-primary" role="button">Novo</a>
            </div>
          </div>
          
          <TabelaPaginada apiNome="empresas/buscar" quantidadePorPagina="10"
            filtro={filtroTabela} enableConsoleLog='true'
            colunas={[
              {nome:"CNPJ",               bind:"cnpj", formato: "cpfOuCnpj", url:"/app/empresas/{idempresa}"},
              {nome:"Razão social",       bind:"razaosocial"},
              {nome:"Nome fantasia",      bind:"nomefantasia"},
              {nome:"Cidade",             bind:"cidade"},
              {nome:"Editar", bind:"idempresa", url:"/app/empresas/{idempresa}", formato:"buttonEdit"}
            ]} />
        </div>
      }
    </div>
  );
}

export default Empresas;