import React, {useState, useEffect} from 'react';
import TabelaPaginada from '../components/TabelaPaginada';
import { formatDate, formatoStatusAcordo, formatoStatusDivida, getYearsDiff } from '../components/Util';
import { useLocation } from 'react-router';
import api from '../auth/Api';
import { logout } from '../auth/Auth';
import DateSelect from '../components/DateSelect';

function RelatorioFinanceiro() {
  let [valorFiltro, setValorFiltro] = useState('');
  let [de, setDe] = useState('');
  let [ate, setAte] = useState('');

  useEffect(() => {
  }, []);

  function renderListScreen() {
    return (<div>
      <div className="form-inline">
        <div className="col" style={colStyleForm}>
          <label htmlFor="filtro-empresa">Filtro: </label>
          <input type="text" className="form-control" id="filtro-empresa"
            placeholder="CNPJ Empresa ou Nome" onChange={(e)=>{setValorFiltro(e.target.value)}} />
        </div>
        <div className="col" style={colStyleForm}>
          <label htmlFor="filtro-empresa">De: </label>
          <DateSelect name="data" value={de} onChange={e => setDe(e)} />
        </div>
        <div className="col" style={colStyleForm}>
          <label htmlFor="filtro-empresa">Até: </label>
          <DateSelect name="data" value={ate} onChange={e => setAte(e)} />
        </div>
      </div>
          
      <TabelaPaginada apiNome="financeiro/relatorio/parcelas" quantidadePorPagina="10"
        filtro={"q="+valorFiltro+"&de="+de+"&ate="+ate}
        colunas={[
          {nome:"CNPJ Empresa", bind:"empresa.cnpj", formato:"cpfOuCnpj"},
          {nome:"Nome Empresa", bind:"empresa.nomefantasia",  url:"/app/empresas/{empresa.idempresa}"},
          {nome:"CPF/CNPJ Devedor", bind:"devedor.cpfcnpj", formato:"cpfOuCnpj"},
          {nome:"Nome Devedor", bind:"devedor.nomerazao",  url:"/app/devedores/{devedor.iddevedor}"},
          {nome:"Data dívida", bind:"datadivida", formato:"dateUsToBr"},
          {nome:"Valor dívida", bind:"valorcapital", formato:"dinheiro"},
          {nome:"Status", bind:"statusDivida", url:"/app/buscar-divida/{iddivida}", formato: formatoStatusDivida },
          {nome:"Aprovado?", bind:"isAcordoAprovado", formato:"badge", url:"/app/financeiro/{iddivida}" }
        ]} />
    </div>)
  }

  const colStyleForm = {paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold'};
  return (    
    <div className="content mw-full">
      { renderListScreen() }
    </div>
  );
}

export default RelatorioFinanceiro;