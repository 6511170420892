import React from 'react';
import { useLocation } from 'react-router-dom';
import { isAdminAuthenticated, isEmpresaAuthenticated } from '../auth/Auth';

function MenuLink(props) {
  const urlBase = "/app";
  const lUrl = urlBase + (props.url || "/");

  const path = useLocation().pathname;
  const paginas = path.substr(1).split("/");
  if(paginas.includes("app")) {
    paginas.splice(paginas.indexOf("app"), 1);
  }

  let classes = "sidebar-link sidebar-link-with-icon";
  if(paginas.length===0 && props.url === undefined) {
    classes = classes + " active";
  } else {
    paginas.forEach(pagina => {
      if( ( pagina !== "" && lUrl.includes(pagina) ) || 
              ( props.url === undefined && pagina === "" ) ) {
        classes = classes + " active";
      }
    });
  }

  return (
    <a href={lUrl} className={classes}>
      <span className="sidebar-icon">
        <i className={props.icone}></i>
      </span>
      {props.descricao}
      {props.building ? <span className="badge badge-danger" style={{marginLeft: '5px'}} >Em construção</span> : null}
      
    </a>
  );
}

function MenuLateral() {
  return (
    <div className="sidebar">
      <div className="sidebar-menu">
        <a href="/app/" className="sidebar-brand">
          <img src={process.env.PUBLIC_URL + '/sec.png'} alt="logotipo" style={{width: '55%', height: 'auto'}} />
          <span style={{paddingLeft: '8px'}}></span>
          <span className="badge badge-primary" style={{marginTop: '-20px'}} >Beta v1.0</span>
        </a>
        <br/>

        {isEmpresaAuthenticated() ?
          <div>
            <div className="sidebar-divider"></div>
            <MenuLink descricao="Painel" icone="fas fa-tachometer-alt" />        
            <br/>
          </div>
          : 
          <div>
            <h5 className="sidebar-title sidebar-title-with-icon">
              <i className="fas fa-file-invoice-dollar"></i> Módulo de cobranças
            </h5>
            <div className="sidebar-divider"></div>
            <MenuLink descricao="Início" icone="fas fa-home" />        
            <MenuLink descricao="Buscar dívida" url="/buscar-divida" icone="fas fa-search" />
            <br/>
          </div>
        }  

        {isAdminAuthenticated() ?
          <div>
            <h5 className="sidebar-title"><i className="fas fa-cogs"></i> Administrador</h5>
            <div className="sidebar-divider"></div>
            <MenuLink descricao="Dívidas" url="/dividas" icone="fas fa-file-invoice-dollar" />
            <MenuLink descricao="Devedores" url="/devedores" icone="fas fa-id-card" />
            <MenuLink descricao="Empresas" url="/empresas" icone="fas fa-building" />
            <MenuLink descricao="Importação" url="/importacao" icone="fas fa-upload" />
            <MenuLink descricao="Financeiro" url="/financeiro" icone="fas fa-coins" building="1" />
            {/* <MenuLink descricao="Rel. Financeiro" url="/relatorio-financeiro" icone="fas fa-coins" building="1" /> */}
          </div>
          : <span></span>
        }
      </div>
    </div>
  );
}

export default MenuLateral;
