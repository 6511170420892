import React, {useState, useEffect}  from 'react';
import TabelaPaginada from '../components/TabelaPaginada';
import { formatoStatusDivida } from '../components/Util';
import { useLocation } from 'react-router-dom';
import api from '../auth/Api';
import { logout } from '../auth/Auth';

function Dividas() {
  let urlId = useLocation().pathname.replace("/app/dividas", "").replace("/", "");
  
  let isNew = false;
  if(urlId==="new") {
    isNew = true;
  }
  urlId = Number(urlId);
  
  let isEditing = false;
  if(urlId) {
    isEditing = true;
  }

  const caminhoAtual = useLocation().pathname;
  let [foiEditado, setFoiEditado] = useState(false);
  let [formularioMontado, setFormularioMontado] = useState(null);
  let [dadosFormulario, setDadosFormulario] = useState({});
  // let dadosApi = null;
  let [dadosBusca, setDadosBusca] = useState(null);

  let [filtroTabela, setFiltroTabela] = useState('');
  let [cnpjEmpresa, setCnpjEmpresa] = useState('');
  let [cpfCnpjDevedor, setCpfCnpjDevedor] = useState('');
  let [actives, setActives] = useState(false);

  let [isFirstTime, setIsFirstTime] = useState(1);

  useEffect(() => {
    if(isEditing) {
      if(!dadosBusca) {
        if(isFirstTime)
          buscar(urlId);
      } else {
        setFormularioMontado(buildForm());
      }
    } else if(isNew) {
      setFormularioMontado(buildForm());
    } else {
      montarFiltroTabela();
    }

    if(isFirstTime)
      setIsFirstTime(isFirstTime+1);
  },[cnpjEmpresa, cpfCnpjDevedor, actives, dadosBusca, dadosFormulario, foiEditado]);

  function buscar() {
    const url = 'dividas/buscar/'+urlId;
    api.get(url)
      .then((response) => processar(response.data))
      .catch((err) => {
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href='/';
          return;
        }
      });
  }

  function processar(value) {
    // dadosApi = value;
    setDadosBusca(value);
    setFormularioMontado(buildForm());
  }

  function getBindValue(linha, bind) {
    if (linha === null)
      return "";

    let b = (bind || "");
    const nivelDeAcesso = b.split(".");
    let binded = linha;
    nivelDeAcesso.forEach(n => {
      binded = binded[n];
    });
    return binded;
  }

  function buildInlineInput(bind, descricao, required, disabled, comp) {
    const req = required ? "required" : "";
    const des = disabled && !comp ? "disabled" : "";
    // if(!dadosFormulario[bind]) {
    if(isFirstTime<3) {
      dadosFormulario[bind] = (getBindValue(dadosBusca, bind) || "" );
    }
    return (<div className="col form-inline">
      {comp}
      {descricao ? 
        <label htmlFor={bind} className={req}>&nbsp;{descricao}</label>
        : null}
      
      <input type="text" className="form-control" id={bind} disabled={des}
        value={dadosFormulario[`${bind}`] ? dadosFormulario[`${bind}`] : ""} 
        required={req} onChange={(e) => {onChangeField(e, bind, e.target.value)}}/>
    </div>);
  }

  function buildInlineStatus(bind, descricao, required, disabled) {
    const req = required ? "required" : "";
    const des = disabled ? "disabled" : "";
    if(!dadosFormulario[bind] && !foiEditado) {
      dadosFormulario[bind] = (getBindValue(dadosBusca, bind) || "" );
    }
    return (<div className="col form-inline">
      {descricao ? 
        <label htmlFor={bind} className={req}>&nbsp;{descricao}</label>
        : null}

      <select className="form-control" id={bind} disabled={des} 
          value={dadosFormulario[`${bind}`] ? dadosFormulario[`${bind}`] : ""} 
          required={req} onChange={(e) => {onChangeField(e, bind, e.target.value)}} >
        <option value="Processo de cobrança">Processo de cobrança</option>
        <option value="Negociando">Negociando</option>
        <option value="Tentativa">Tentativa</option>
        <option value="Recado Formal">Recado Formal</option>
        <option value="Recado com Terceiro">Recado com Terceiro</option>
        <option value="Recusa">Recusa</option>
        <option value="Sem condições">Sem condições</option>
        <option value="Deslocalizado">Deslocalizado</option>
        <option value="Acordo">Acordo</option>
        <option value="Liquidado">Liquidado</option>
        <option value="Falecido">Falecido</option>
        <option value="Não cobrar">Não cobrar</option>
        <option value="Atraso">Atraso</option>
      </select>
    </div>);
  }

  function searchFieldLine(apiName, bindBase, bindId, bindKey, descKey, bindDesc, descDesc, urlRedirect) {
    function onChangeFieldLocal(bind, value) {
      setDadosFormulario({...dadosFormulario, [`${bind}`]:value});
      setFoiEditado(true);
    }

    if(isFirstTime<3) {
      dadosFormulario[bindBase+bindId] = (getBindValue(dadosBusca, bindBase+bindId) || "" );
      dadosFormulario[bindBase+bindKey] = (getBindValue(dadosBusca, bindBase+bindKey) || "" );
      dadosFormulario[bindBase+bindDesc] = (getBindValue(dadosBusca, bindBase+bindDesc) || "" );
    }

    let valueField = dadosFormulario[`${bindBase+bindKey}`] ? dadosFormulario[`${bindBase+bindKey}`] : ""
    let left = (
      <div className="col form-inline" key="1">
        <label htmlFor={bindBase+bindKey} className="required">&nbsp;{descKey}</label>
        <input type="text" className="form-control" id={bindBase+bindKey}
          onChange={(e) => {onChangeFieldLocal(bindBase+bindKey, e.target.value)}} required="required"
          value={valueField} />
      </div>
    )

    let right = (
      <div className="col form-inline" key="2">
        <label htmlFor={bindBase+bindDesc}>&nbsp;{descDesc}</label>
        <input type="text" className="form-control" id={bindBase+bindDesc}
          value={dadosFormulario[`${bindBase+bindDesc}`] ? dadosFormulario[`${bindBase+bindDesc}`] : ""}
          disabled="disabled"/>
      </div>
    )

    function loadDesc() {
      const url = apiName+'/?paginaAtual=0&quantidadePorPagina=1&'+bindKey+'='+valueField;
      let result = null;
      api.get(url)
        .then((response) => {
          result = response.data.itens[0]
          let valueDescFieldSearch = ""
          let valueIdFieldSearch = ""
          if (result) {
            valueIdFieldSearch = result[`${bindId}`]
            valueDescFieldSearch = result[`${bindDesc}`]
          }

          dadosFormulario[bindBase+bindId] = valueIdFieldSearch;
          setDadosFormulario({...dadosFormulario, [`${bindBase+bindId}`]:valueIdFieldSearch});
          dadosFormulario[bindBase+bindDesc] = valueDescFieldSearch;
          setDadosFormulario({...dadosFormulario, [`${bindBase+bindDesc}`]:valueDescFieldSearch});
          setFoiEditado(true);
        })
    }

    let buttonSearch = (
      <button className="btn btn-primary" type="button" 
        onClick={() => {
          loadDesc()
        }}
      >
        <i className="fas fa-search"></i>
      </button>
    );

    let buttons = (
      <div className="col form-inline" key="3">
        <div>
          &nbsp;&nbsp;
          {buttonSearch}
          &nbsp;&nbsp;
          <button className="btn" type="button" 
            onClick={() => {
              window.location.href=urlRedirect+"/"+getBindValue(dadosBusca, bindBase+bindId)
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
      </div>
    )

    return ([left, right, buttons])
  }

  function buildForm() {
    return (
      <div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {
              searchFieldLine(
                "empresas", "empresa.", "idempresa",
                "cnpj", "CNPJ Empresa", 
                "nomefantasia", "Nome Empresa", "/app/empresas")
              }
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {
              searchFieldLine(
                "devedores", "devedor.", "iddevedor", 
                "cpfcnpj", "CPF/CNPJ Devedor",
                "nomerazao", "Nome Devedor", "/app/devedores"
              )
            }
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("valorcapital", "Valor capital", true)}
            {buildInlineInput("datadivida", "Data dívida", true)}
            <div className="col form-inline"></div>
          </div>
        </div>
        <div className="form-row row-eq-spacing">
          <div className="col form-inline">
            {buildInlineInput("observacao", "Observação", false)}
            {buildInlineStatus("statusDivida", "Status", true)}
            <div className="col form-inline"></div>
          </div>
        </div>
      </div>
    );
  }
  
  function onChangeField(e, bind, value) {
    setDadosFormulario({...dadosFormulario, [`${bind}`]:value});
    setFoiEditado(true);
  }

  function salvarFormulario() {
    let dadosEnvio = {
      ...dadosFormulario,
      "iddivida":urlId,
      "empresa": {
        "idempresa": dadosFormulario["empresa.idempresa"],
      },
      "devedor": {
        "iddevedor": dadosFormulario["devedor.iddevedor"],
      },
    };
    delete dadosEnvio["devedor.cpfcnpj"]
    delete dadosEnvio["devedor.iddevedor"]
    delete dadosEnvio["devedor.nomerazao"]
    delete dadosEnvio["empresa.cnpj"]
    delete dadosEnvio["empresa.idempresa"]
    delete dadosEnvio["empresa.nomefantasia"]

    api.put("dividas", dadosEnvio)
      .then((response) => {
        toastSuccessAlert("salvo");
        // setFoiEditado(false);
      }).catch((err) => {
        toastErrorAlert("salvar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }

  function deletarFormulario() {
    api.delete("dividas/"+urlId)
      .then((response) => {
        toastSuccessAlert("deletado");
        window.location.href="/app/dividas";
      })
      .catch((err) => {
        toastErrorAlert("deletar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=caminhoAtual;
          return;
        }
      });
  }
  
  // Toasts success alert
  function toastSuccessAlert(operacao) {
    window.halfmoon.initStickyAlert({
      content: "O registro foi "+operacao+" com sucesso.",
      title: "Empresa",
      alertType: "alert-success",
      fillType: "filled-lm"
    });
  }
  
  // Toasts success alert
  function toastErrorAlert(operacao, mensagem) {
    window.halfmoon.initStickyAlert({
      content: "Não foi possível "+operacao+" o registro.<br><br>"+mensagem,
      title: "Empresa",
      alertType: "alert-error",
      fillType: "filled-lm"
    });
  }

  const colStyleForm = {paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold'};

  function montarFiltroTabela() {
    let filtro = "";
    if(actives) {
      filtro = "empresa.active=ACTIVE&";
    }
    
    if(cnpjEmpresa !== "") {
      filtro = "empresa.cnpj=";
      if(cnpjEmpresa.includes(".")) {
        filtro = filtro + cnpjEmpresa.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
      } else {
        filtro = filtro + cnpjEmpresa;
      }
    }
    if(cpfCnpjDevedor !== "") {
      filtro = filtro !== "" ? filtro + "&" : filtro;
      filtro = filtro + "devedor.cpfcnpj=";
      if(cpfCnpjDevedor.includes(".")) {
        //000.000.000-00
        filtro = filtro + cpfCnpjDevedor.replaceAll(".", "").replaceAll("-", "");
      } else {
        filtro = filtro + cpfCnpjDevedor;
      }
    }
    setFiltroTabela(filtro);
  }

  return (
    <div className="content">
      {isEditing || isNew ?
        <div>
          <h1 className="content-title">Dívida</h1>
          <div className="sticky-alerts"></div>
          <div className="mw-full">
            {formularioMontado}

            <button className="btn btn-primary" type="button" onClick={salvarFormulario}
                      disabled={!foiEditado ? "disabled" : ""}>
              <i className="fas fa-save"></i> Salvar
            </button>
            &nbsp;
            {isEditing ?
              <button className="btn btn-primary" type="button" onClick={deletarFormulario}>
                <i className="fas fa-trash-alt"></i> Deletar
              </button>
              : null
            }
          </div>
        </div>
      :
        <div className="mw-full form-inline">
          <div className="col form-inline" style={colStyleForm}>
            <label htmlFor="cnpj-empresa">CNPJ Empresa: </label>
            <input type="text" className="form-control" id="cnpj-empresa"
              placeholder="CNPJ Empresa" onChange={(e)=>{setCnpjEmpresa(e.target.value)}} />
          </div>
          <div className="col form-inline" style={colStyleForm}>
            <label htmlFor="cpfcnpj-devedor">CPF/CNPJ Devedor: </label>
            <input type="text" className="form-control" id="cpfcnpj-devedor" 
              placeholder="CPF/CNPJ Devedor" onChange={(e)=>{setCpfCnpjDevedor(e.target.value)}} />
          </div>
          <div className="col-sm">
            <label style={{fontWeight: 'bold'}}>Filtrar ativos?</label>
            <div className="custom-switch">
              <input type="checkbox" id="switch-2" value={actives} defaultChecked={actives}
                onChange={(e) => {setActives(!actives)}} /> 
              <label htmlFor="switch-2"></label>
            </div>
          </div>

          <a href="/app/dividas/new" className="btn btn-primary" role="button">Nova</a>
          <TabelaPaginada apiNome="dividas" quantidadePorPagina="9" 
            filtro={filtroTabela}
            colunas={[
              {nome:"CNPJ Empresa", bind:"empresa.cnpj", formato:"cpfOuCnpj"},
              {nome:"Nome Empresa", bind:"empresa.nomefantasia", url:"/app/empresas/{empresa.idempresa}"},
              {nome:"CPF/CNPJ Devedor", bind:"devedor.cpfcnpj", formato:"cpfOuCnpj"},
              {nome:"Nome Devedor", bind:"devedor.nomerazao", url:"/app/devedores/{devedor.iddevedor}"},
              {nome:"Data dívida", bind:"datadivida", formato:"dateUsToBr"},
              {nome:"Valor dívida", bind:"valorcapital", formato:"dinheiro"},
              {nome:"Status", bind:"statusDivida", url:"/app/dividas/{iddivida}", formato: formatoStatusDivida },
              {nome:"Editar", bind:"iddivida", url:"/app/dividas/{iddivida}", formato:"buttonEdit"}
            ]} />
        </div>
      }
    </div>
  );
}

export default Dividas;