import React, {useState, useEffect} from 'react';
import TabelaPaginada from '../components/TabelaPaginada';
import { formatDate, formatoStatusAcordo, formatoStatusDivida, getYearsDiff } from '../components/Util';
import { useLocation } from 'react-router';
import api from '../auth/Api';
import { logout } from '../auth/Auth';
import DateSelect from '../components/DateSelect';

function Financeiro() {
  let [valorFiltro, setValorFiltro] = useState('');
  let [divida, setDivida] = useState(null);
  let [exibirPainelDivida, setExibirPainelDivida] = useState(true);

  //Novo Acordo
  let [numeroDeParcelas, setNumeroDeParcelas] = useState(0)
  let [parcelas, setParcelas] = useState([]);
  let [totalParcelasAcordo, setTotalParcelasAcordo] = useState(0)
  let [totalAcordo, setTotalAcordo] = useState(0)
  let [exibeRegistroCobranca, setExibeRegistroCobranca] = useState(false)

  let [valorDireitoEmpresa, setValorDireitoEmpresa] = useState(0);
  let [percentilDireitoEmpresa, setPercentilDireitoEmpresa] = useState(0);

  let [isAcordoAprovado, setIsAcordoAprovado] = useState(false);

  let [totalPago, setTotalPago] = useState(0);
  let [parcelasRestantes, setParcelasRestantes] = useState(0);
  let [saldoAReceber, setSaldoAReceber] = useState(0);

  //Acordo selecionado
  let [acordo, setAcordo] = useState(null);

  const ids = useLocation().pathname.replace("/app/financeiro", "").replace("/", "").split("/");
  const idDivida = ids[0];
  const idAcordo = ids[1];

  let [dividaBuscada, setDividaBuscada] = useState(false);
  let [acordoBuscado, setAcordoBuscado] = useState(false);

  const isNew = idAcordo === "new";

  async function fetchApi() {
    if (idDivida > 0 && divida === null && !dividaBuscada) {
      setDividaBuscada(true);
      buscarDivida(idDivida);
    }

    if (idAcordo > 0 && acordo === null && !acordoBuscado) {
      setAcordoBuscado(true);
      await buscarAcordo(idAcordo);
    }

    if (dividaBuscada && acordoBuscado && parcelas != null && parcelas.length > 0) {
      console.log('parcelas')
      console.log(parcelas)

      let totPago = 0.0;
      let numeroDeParcelasPendentes = 0;
      let totNaoPago = 0.0;
      for (let i in parcelas) {
        const p = parcelas[i];
        if (p.isPago) {
          totPago += parseFloat(p.valor);
        } else {
          numeroDeParcelasPendentes += 1;
          totNaoPago += parseFloat(p.valor);
        }
      }
      
      totPago = Math.round((totPago + Number.EPSILON) * 100) / 100
      totNaoPago = Math.round((totNaoPago + Number.EPSILON) * 100) / 100
      setTotalPago(totPago);
      setSaldoAReceber(totNaoPago);

      setParcelasRestantes(numeroDeParcelasPendentes);
    }
  }

  useEffect(() => {
    fetchApi();
  }, [divida, parcelas, numeroDeParcelas, totalAcordo, totalParcelasAcordo, percentilDireitoEmpresa, totalPago]); //acordo //divida

  function processarDivida(divida) {
    setDivida(divida);
    setTotalAcordo(divida.valorcapital);

    // Campos de cálculo
    if (divida) {
      const dataDivida = Date.parse(divida.datadivida);
      const dataAtual = Date.now();
      const yearsDiff = getYearsDiff(dataAtual, dataDivida);

      let percentilEmpresa = 0;
      if (yearsDiff <= 1) {
        percentilEmpresa = divida.empresa.ateUm;
      } else if (yearsDiff === 2) {
        percentilEmpresa = divida.empresa.ateDois;
      } else if (yearsDiff === 3) {
        percentilEmpresa = divida.empresa.ateTres;
      } else if (yearsDiff === 4) {
        percentilEmpresa = divida.empresa.ateQuatro;
      } else if (yearsDiff === 5) {
        percentilEmpresa = divida.empresa.ateCinco;
      } else if (yearsDiff === 6) {
        percentilEmpresa = divida.empresa.ateSeis;
      } else if (yearsDiff >= 7) {
        percentilEmpresa = divida.empresa.ateN;
      }
      percentilEmpresa = 90

      setPercentilDireitoEmpresa(percentilEmpresa);

      calculaValorDireitoEmpresa(percentilEmpresa, divida.valorcapital);
    }
  }

  function calculaValorDireitoEmpresa(percentilEmpresa, valorcapital) {
    let valorEmpresa = (percentilEmpresa / 100) * valorcapital;
    valorEmpresa = Math.round((valorEmpresa + Number.EPSILON) * 100) / 100;
    setValorDireitoEmpresa(valorEmpresa);
    return valorEmpresa;
  }

  async function buscarDivida(idDivida) {
      const url = 'financeiro/buscar/dividas/'+idDivida;
      await api.get(url)
        .then((response) => processarDivida(response.data))
        .catch((err) => {
          if(err.response.status === 400) {
            
          } else if(err.response.status === 403) {
            logout();
            window.location.href='/';
            return;
          }
        });
  }

  function processarAcordo(acordo) {
    setAcordo(divida);
    setNumeroDeParcelas(acordo.numeroDeParcelas);
    setTotalAcordo(acordo.valorTotalAcordo);
    setValorDireitoEmpresa(acordo.valorTotalCredora);
    setPercentilDireitoEmpresa(acordo.percentualTotalCredora);

    let totParcelas = 0;
    const novasParcelas = [];
    for ( let i in acordo["parcelas"]) {
      const p = acordo["parcelas"][i];
      totParcelas += p.valorTotal + p.valorMulta;
      const pagoEm = formatDate(new Date(p.pagoEm));
      novasParcelas.push({
        idparcela: p.idparcela,
        valor: p.valorTotal,
        data: formatDate(new Date(p.vencimento)),
        valorEmpresa: p.valorCredora,
        isPago: p.pago ? true : false,
        valorMulta: p.valorMulta ? p.valorMulta : 0,
        dataPagamento: pagoEm === '1969-12-30' ? '' : pagoEm,
      });
    }
    setTotalParcelasAcordo(totParcelas);
    setParcelas(novasParcelas);
    setIsAcordoAprovado(acordo.isAcordoAprovado);
    console.log('acordo.isAcordoAprovado');
    console.log(acordo.isAcordoAprovado);
  }

  async function buscarAcordo(idAcordo) {
      const url = 'financeiro/buscar/dividas/acordos/'+idAcordo;
      await api.get(url)
        .then((response) => processarAcordo(response.data))
        .catch((err) => {
          if(err.response.status === 400) {
            
          } else if(err.response.status === 403) {
            logout();
            window.location.href='/';
            return;
          }
        });
  }

  function renderHeaderDivida() {
    const urlNewAcordo = "/app/financeiro/" + idDivida + "/new"
    return (<div className="card" style={{margin: '1.5rem 0rem 1.5rem 0rem'}}>
      <div className="form-row row-eq-spacing">
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Empresa</label>
          <input type="text" className="form-control" id="id1"
            value={divida.empresa.nomefantasia} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;CNPJ</label>
          <input type="text" className="form-control" id="id1"
            value={divida.empresa.cnpj} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Devedor</label>
          <input type="text" className="form-control" id="id1"
            value={divida.devedor.nomefantasia} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;CPF/CNPJ</label>
          <input type="text" className="form-control" id="id1"
            value={divida.devedor.cpfcnpj} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Usuário</label>
          <input type="text" className="form-control" id="id1"
            value={divida.ultimaCobrancaEmAcordo && divida.ultimaCobrancaEmAcordo.usuario ? divida.ultimaCobrancaEmAcordo.usuario.usuario : ""} disabled />
        </div>
      </div>

      <div className="form-row row-eq-spacing">
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Data</label>
          <input type="text" className="form-control" id="id1"
            value={divida.datadivida} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Status</label>
          <input type="text" className="form-control" id="id1"
            value={divida.statusDivida} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Valor</label>
          <input type="text" className="form-control" id="id1"
            value={divida.valorcapital} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Acordo</label>
          <input type="text" className="form-control" id="id1"
            value={divida.isAcordoAprovado ? "Aprovado" : "Pendente"} disabled />
        </div>
        <div className="col form-inline">
          <div className="custom-switch">
            <input type="checkbox" id="switch-2" value={exibeRegistroCobranca} defaultChecked={exibeRegistroCobranca}
              onChange={(e) => {setExibeRegistroCobranca(!exibeRegistroCobranca)}}
            /> 
            <label htmlFor="switch-2">Exibe registro de cobrança</label>
          </div>
        </div>
        { !isNew ?
          <div className="col form-inline">
            <a href={urlNewAcordo} className="btn btn-primary" role="button">Novo Acordo</a>
          </div>
        : null}
      </div>

      {exibeRegistroCobranca ?
        <section dangerouslySetInnerHTML={{ __html: divida.ultimaCobrancaEmAcordo ? divida.ultimaCobrancaEmAcordo.descricaoDoContato : null}} />
      : null}
    </div>);
  }

  function renderListaDeParcelas() {
    return (<div>
      <div className="form-row row-eq-spacing">
        <div className="col form-inline align-items-center">
          <label>&nbsp; Valor da parcela</label>
        </div>
        <div className="col form-inline">
          <label>&nbsp; Vencimento</label>
        </div>
        <div className="col form-inline">
          <label>&nbsp; Valor Empresa</label>
        </div>
        <div className="col form-inline">
          <label>&nbsp; Data pagamento</label>
        </div>
        <div className="col form-inline">
          <label>&nbsp; Valor Multa</label>
        </div>
        <div className="col form-inline">
          <label>&nbsp; Pago?</label>
        </div>
      </div>
      {
        parcelas.map((element, index) => {
          return (
          <div key={index} className="form-row row-eq-spacing">
            <div className="col form-inline">
              <input type="text" data-type="currency" className="form-control" id={"nroParcela"+(index+1)} value={element.valor}
                name="valor" onChange={e => setParcela(index, e)} />
            </div>
            <div className="col form-inline">
              <DateSelect name="data" value={element.data} onChange={e => setDataParcela(index, e)} />
            </div>
            <div className="col form-inline">
              <input type="text" data-type="currency" className="form-control" id={"valorEmpresa"+(index+1)} value={element.valorEmpresa}
                name="valorEmpresa" disabled />
            </div>
            <div className="col form-inline">
              <DateSelect name="data" value={element.dataPagamento} onChange={e => setDataPagamentoParcela(index, e)} />
            </div>
            <div className="col form-inline">
              <input type="text" data-type="currency" className="form-control" id={"valorMulta"+(index+1)} value={element.valorMulta}
                name="valorMulta" onChange={e => setValorMulta(index, e)} disabled={!element.isPago} />
            </div>
            <div className="col form-inline">
              <div className="custom-switch">
                <input type="checkbox" id={"switch-a"+(index+1)} value={element.isPago} defaultChecked={element.isPago}
                  onChange={(e) => {setParcelaPaga(index, !element.isPago, e)}}
                />
                <label htmlFor={"switch-a"+(index+1)}>&nbsp;</label>
              </div>
            </div>
          </div>
        )})
      }
    </div>);
  }

  function setDataParcela(i, e) {
    let newParcelas = [...parcelas];
    newParcelas[i]["data"] = e;
    setParcelas(newParcelas);
  }

  function setDataPagamentoParcela(i, e) {
    let newParcelas = [...parcelas];
    newParcelas[i]["dataPagamento"] = e;
    setParcelas(newParcelas);
  }

  function setValorMulta(i, e) {
    if (e.target.value === "") {
      e.target.value = 0;
    }
    let newParcelas = [...parcelas];
    newParcelas[i]["valorMulta"] = parseFloat(e.target.value);
    setParcelas(newParcelas);
    calculateTotalParcelasPagas();
  }

  function setParcelaPaga(i, value, e) {
    let newParcelas = [...parcelas];
    newParcelas[i]["isPago"] = value;
    setParcelas(newParcelas);
    calculateTotalParcelasPagas();
  }

  function calculateTotalParcelasPagas() {
    let totalParc = 0;
    for(let i=0; i<parcelas.length; i++) {
      const p = parcelas[i];
      totalParc += p.valor;
      if (p.isPago) {
        totalParc += p.valorMulta;
      }
    }
    setTotalParcelasAcordo(totalParc);
  }

  function setParcela(i, e) {
    let newParcelas = [...parcelas];
    newParcelas[i][e.target.name] = parseFloat(e.target.value);

    let total = 0
    for( let j=newParcelas.length; j--; ) {
      total += parseFloat(newParcelas[j][e.target.name]);
    }
    total = Math.round((total + Number.EPSILON) * 100) / 100;

    let totalEmpresa = 0;
    for( let j=newParcelas.length; j--; ) {
      let prclEmpresa = (((percentilDireitoEmpresa / 100) * divida.valorcapital) / total) * newParcelas[j]["valor"];
      prclEmpresa = Math.round((prclEmpresa + Number.EPSILON) * 100) / 100
      newParcelas[j]["valorEmpresa"] = Math.round((prclEmpresa + Number.EPSILON) * 100) / 100;
      totalEmpresa += newParcelas[j]["valorEmpresa"];
    }
    setParcelas(newParcelas);

    setTotalAcordo(total);
    setTotalParcelasAcordo(total);
    setValorDireitoEmpresa(totalEmpresa);
  }

  function changeNroParcelas(numeroDeParcelas) {
    const totalParcelas = totalAcordo;
    const listaParcelas = [];
    let total = 0;
    let totalDireitoEmpresa = 0;

    const vlrDireitoEmpresa = calculaValorDireitoEmpresa(percentilDireitoEmpresa, divida.valorcapital);

    let data = new Date(Date.now());
    data = new Date(data.getFullYear()+"-"+(data.getMonth() + 1)+"-11")

    if (data.getDay() >= 10) {
      data = new Date(data.setMonth(data.getMonth()+1));
    }

    if (numeroDeParcelas > 0) {
      setNumeroDeParcelas(numeroDeParcelas);
      const valorParcela = Math.round(((totalParcelas / numeroDeParcelas) + Number.EPSILON) * 100) / 100;
      const vlrEmpresa = Math.round(((vlrDireitoEmpresa / numeroDeParcelas) + Number.EPSILON) * 100) / 100;
      let i = 0;
      for(; i < numeroDeParcelas-1; i++) {
        const isPago = parcelas && parcelas[i] && parcelas[i].isPago;
        const valorMulta = parcelas && parcelas[i] ? parcelas[i].valorMulta : 0;
        const dataPagamento = parcelas && parcelas[i] ? parcelas[i].dataPagamento : null;
        listaParcelas.push({
          valor: valorParcela,
          data: formatDate(data),
          valorEmpresa: vlrEmpresa,
          isPago,
          valorMulta,
          dataPagamento,
        });
        total += valorParcela;
        if (isPago) {
          total += valorMulta;
        }
        totalDireitoEmpresa += vlrEmpresa;
        data = new Date(data.setMonth(data.getMonth()+1));
      }

      const parcelaFinal = Math.round(((totalParcelas - total) + Number.EPSILON) * 100) / 100;
      const vlrEmpresaFinal = Math.round(((vlrDireitoEmpresa - totalDireitoEmpresa) + Number.EPSILON) * 100) / 100;
      const isPago = parcelas && parcelas[i] && parcelas[i].isPago;
      const valorMulta = parcelas && parcelas[i] ? parcelas[i].valorMulta : 0;
      const dataPagamento = parcelas && parcelas[i] ? parcelas[i].dataPagamento : null;
      listaParcelas.push({
        valor: parcelaFinal,
        data: formatDate(data),
        valorEmpresa: vlrEmpresaFinal,
        isPago: isPago,
        valorMulta,
        dataPagamento,
      });

      if (isPago) {
        total += valorMulta;
      }
      total = Math.round((total + Number.EPSILON) * 100) / 100;

      setTotalParcelasAcordo(total);
    }
    setParcelas(listaParcelas);
  }

  function changeTotalAcordo(totalAcordo) {
    const listaParcelas = [];
    let total = 0;
    let totalDireitoEmpresa = 0;

    let vlrDireitoEmpresa = calculaValorDireitoEmpresa(percentilDireitoEmpresa, divida.valorcapital);

    if (numeroDeParcelas > 0) {
      const valorParcela = Math.round(((totalAcordo / numeroDeParcelas) + Number.EPSILON) * 100) / 100
      const vlrEmpresa = Math.round(((vlrDireitoEmpresa / numeroDeParcelas) + Number.EPSILON) * 100) / 100

      let i = 0;
      for(; i < numeroDeParcelas-1; i++) {
        const valorMulta = parcelas && parcelas[i] ? parcelas[i].valorMulta : 0;
        const dataPagamento = parcelas && parcelas[i] ? parcelas[i].dataPagamento : 0;
        listaParcelas.push({
          valor: valorParcela,
          data: parcelas[i]["data"],
          valorEmpresa: vlrEmpresa,
          isPago: parcelas[i]["isPago"],
          valorMulta,
          dataPagamento,
        });
        total += valorParcela;
        if (parcelas[i].isPago) {
          total += valorMulta;
        }
        totalDireitoEmpresa += vlrEmpresa;
      }

      const parcelaFinal = Math.round(((totalAcordo - total) + Number.EPSILON) * 100) / 100;
      const vlrEmpresaFinal = Math.round(((vlrDireitoEmpresa - totalDireitoEmpresa) + Number.EPSILON) * 100) / 100;
      const valorMulta = parcelas && parcelas[i] ? parcelas[i].valorMulta : 0;
      const dataPagamento = parcelas && parcelas[i] ? parcelas[i].dataPagamento : 0;
      listaParcelas.push({
        valor: parcelaFinal,
        data: parcelas[i]["data"],
        valorEmpresa: vlrEmpresaFinal,
        isPago: parcelas[i]["isPago"],
        valorMulta,
        dataPagamento,
      });
      total += parcelaFinal;
      if (parcelas[i].isPago) {
        total += valorMulta;
      }
      total = Math.round((total + Number.EPSILON) * 100) / 100;

      setTotalParcelasAcordo(total);
    }
    setParcelas(listaParcelas);
    setTotalAcordo(totalAcordo);
  }

  function changePercentilDireitoEmpresa(value) {
    setPercentilDireitoEmpresa(value);
    let percParcelaEmpresa = ((value / 100) * divida.valorcapital) / totalAcordo;

    let newParcelas = [...parcelas];
    let totalDireitoEmpresa = 0;
    if (numeroDeParcelas > 0) {
      for( let i=numeroDeParcelas; i--; ) {
        const vlrParcela = newParcelas[i]["valor"];
        const vlrDireitoEmpresa = Math.round(((percParcelaEmpresa * vlrParcela) + Number.EPSILON) * 100) / 100;
        newParcelas[i]["valorEmpresa"] = vlrDireitoEmpresa;
        totalDireitoEmpresa += vlrDireitoEmpresa;
      }
    }
    setParcelas(newParcelas);
    setValorDireitoEmpresa(totalDireitoEmpresa);
  }

  function renderPainelCalculos() {
    //valor total que falta ser pago
    return (<div>
      <div className="form-row row-eq-spacing">
        <div className="col form-inline">
          <label htmlFor="id1">&nbsp;Total das parcelas</label>
        </div>
        <div className="col form-inline">
          <input type="text" className="form-control" id="id1" value={totalParcelasAcordo} disabled />
        </div>
        <div className="col form-inline">
          <label htmlFor="percentilAcordo">&nbsp; Percentual Empresa</label>
        </div>
        <div className="col form-inline">
          <input type="text" data-type="currency" className="form-control" value={percentilDireitoEmpresa}
            onChange={(e) => {changePercentilDireitoEmpresa(e.target.value)}} />
        </div>
        <div className="col form-inline">
          <label htmlFor="percentilAcordo">&nbsp; Valor direito Empresa</label>
        </div>
        <div className="col form-inline">
          <input type="text" data-type="currency" className="form-control" value={valorDireitoEmpresa} disabled/>
        </div>
      </div>
      <div className="form-row row-eq-spacing">
        <div className="col form-inline">
          <label htmlFor="percentilAcordo">&nbsp; Total pago do acordo</label>
        </div>
        <div className="col form-inline">
          <input type="text" data-type="currency" className="form-control" value={totalPago} disabled/>
        </div>
        <div className="col form-inline">
          <label htmlFor="percentilAcordo">&nbsp; Parcelas restantes</label>
        </div>
        <div className="col form-inline">
          <input type="text" data-type="currency" className="form-control" value={parcelasRestantes} disabled/>
        </div>
        <div className="col form-inline">
          <label htmlFor="percentilAcordo">&nbsp; Saldo a receber</label>
        </div>
        <div className="col form-inline">
          <input type="text" data-type="currency" className="form-control" value={saldoAReceber} disabled/>
        </div>
      </div>
      <div className="form-row row-eq-spacing">
        <div className="col form-inline">
          <div className="custom-switch">
            <input type="checkbox" id="switch-3" value={isAcordoAprovado} defaultChecked={isAcordoAprovado}
              onChange={(e) => {setIsAcordoAprovado(!isAcordoAprovado)}}
            /> 
            <label htmlFor="switch-3">Aprovado?</label>
          </div>
        </div>
          {isNew ? null :
            <div className="col form-inline">
              <button className="btn" type="button" onClick={deletarAcordoAtual}>
                <i className="fas fa-trash"></i> Deletar
              </button>
            </div>
          }
        <div className="col form-inline">
          <button className="btn btn-primary" type="button" onClick={isNew ? salvarNovoAcordo : salvarAcordoAtual} disabled={numeroDeParcelas > 0 ? false : true} >
            <i className="fas fa-floppy-disk"></i> Salvar {isNew ? " Novo Acordo" : null}
          </button>
        </div>
      </div>

    </div>);
  }

  function deletarAcordoAtual() {
    api.delete("financeiro/dividas/acordo/"+idAcordo, null)
      .then((response) => {
        window.location.href="/app/financeiro/"+idDivida;
      }).catch((err) => {
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=useLocation().pathname;
          return;
        }
      });
  }

  function salvarNovoAcordo() {
    const novoAcordo = {
      parcelas, totalAcordo, percentilDireitoEmpresa, valorDireitoEmpresa, isAcordoAprovado,
    }
    
    api.post("financeiro/dividas/"+idDivida+"/acordos/new", novoAcordo)
      .then((response) => {
        window.location.href=response.data.idacordo
      }).catch((err) => {
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=useLocation().pathname;
          return;
        }
      });
  }

  function salvarAcordoAtual() {
    const parcelasAtual = [];
    for (const i in parcelas) {
      const p = parcelas[i];
      parcelasAtual.push({
        vencimento: p.data,
        valorTotal: p.valor,
        valorCredora: p.valorEmpresa,
        pago: p.isPago,
        pagoEm: p.dataPagamento,
        valorMulta: p.valorMulta,
      });
    }

    const acordo = {
      parcelas: parcelasAtual,
      valorTotalAcordo: totalAcordo,
      percentualTotalCredora: percentilDireitoEmpresa,
      valorTotalCredora: valorDireitoEmpresa,
      aprovado: isAcordoAprovado,
    }
    // console.log(parcelasAtual);
    // console.log(acordo);

    api.post("financeiro/dividas/acordo/"+idAcordo, acordo)
      .then((response) => {
        window.location.href=idAcordo
      }).catch((err) => {
        // console.log("salvar", err.response.data.error);
        if(err.response.status === 400) {
          
        } else if(err.response.status === 403) {
          logout();
          window.location.href=useLocation().pathname;
          return;
        }
      });
  }

  function renderAcordoScreen(renderPainelDivida) {
    return (<div>
      { renderPainelDivida ?
        <div>
          <h1 className="content-title">Divida {renderPainelDivida}</h1>
          <div className="col form-inline">
            <div className="custom-switch">
              <input type="checkbox" id="switch-1" value={exibirPainelDivida} defaultChecked={exibirPainelDivida}
                onChange={(e) => {setExibirPainelDivida(!exibirPainelDivida)}}
              /> 
              <label htmlFor="switch-1">Exibe painel de dívida</label>
            </div>
          </div>
        </div> : null }
      <div className="mw-full">
        { divida ? 
          <div>
            { exibirPainelDivida && renderPainelDivida ? renderHeaderDivida() : <br/> }
            <h1 className="content-title">Novo Acordo</h1>
            <div className="form-row row-eq-spacing">
              <div className="col form-inline">
                <label htmlFor="id1">&nbsp;Número de parcelas</label>
                <input type="text" className="form-control" id="id1" value={numeroDeParcelas}
                  onChange={(e)=>{changeNroParcelas(e.target.value)}} />
              </div>
              <div className="col form-inline">
                <label htmlFor="id1">&nbsp;Total do acordo</label>
                <input type="text" className="form-control" id="id1" value={totalAcordo}
                  onChange={(e)=>{changeTotalAcordo(e.target.value)}} />
              </div>
            </div>

            {renderListaDeParcelas()}
            <div className="form-row row-eq-spacing">
              {renderPainelCalculos()}
            </div>
          </div>
        : null }
      </div>
    </div>);
  }

  function renderSelectedDividaScreen() {
    const urlListAcordos = "financeiro/buscar/dividas/"+idDivida+"/acordos";
    return (
        <div>
          {divida ?
          <div>
            <h1 className="content-title">Divida</h1>
            <div className="col form-inline">
              <div className="custom-switch">
                <input type="checkbox" id="switch-1" value={exibirPainelDivida} defaultChecked={exibirPainelDivida}
                  onChange={(e) => {setExibirPainelDivida(!exibirPainelDivida)}}
                /> 
                <label htmlFor="switch-1">Exibe painel de dívida</label>
              </div>
            </div>
            <div className="mw-full">
              {exibirPainelDivida ? renderHeaderDivida() : null}
              <TabelaPaginada apiNome={urlListAcordos} quantidadePorPagina="10" selecionado={idAcordo}
                colunas={[
                  {nome: "Sel.", bind:"idacordo", formato:"selecaoSimples"},
                  {nome:"Id", bind:"idacordo"},
                  {nome:"Data do acordo", bind:"data", formato:"dateUsToBr"},
                  {nome:"Valor total do acordo", bind:"valorTotalAcordo", formato:"dinheiro"},
                  {nome:"Número de parcelas", bind:"numeroDeParcelas"},
                  {nome:"Aprovado?", bind:"isAcordoAprovado", formato:"badge", url:"/app/financeiro/"+idDivida+"/{idacordo}"},
                  {nome:"Status", bind:"status", formato:"badge", formato: formatoStatusAcordo}
                ]} />
            </div>
            { idAcordo ? renderAcordoScreen(false) : null }
          </div>
          : null}
        </div>
      );
  }

  function renderListScreen() {
    return (<div>
      <div className="form-inline">
        <div className="col" style={colStyleForm}>
          <label htmlFor="filtro-empresa">Filtro: </label>
          <input type="text" className="form-control" id="filtro-empresa"
            placeholder="CNPJ Empresa ou Nome" onChange={(e)=>{setValorFiltro(e.target.value)}} />
        </div>
      </div>
          
      <TabelaPaginada apiNome="financeiro/buscar/dividas" quantidadePorPagina="10"
        filtro={"q="+valorFiltro}
        colunas={[
          {nome:"CNPJ Empresa", bind:"empresa.cnpj", formato:"cpfOuCnpj"},
          {nome:"Nome Empresa", bind:"empresa.nomefantasia",  url:"/app/empresas/{empresa.idempresa}"},
          {nome:"CPF/CNPJ Devedor", bind:"devedor.cpfcnpj", formato:"cpfOuCnpj"},
          {nome:"Nome Devedor", bind:"devedor.nomerazao",  url:"/app/devedores/{devedor.iddevedor}"},
          {nome:"Data dívida", bind:"datadivida", formato:"dateUsToBr"},
          {nome:"Valor dívida", bind:"valorcapital", formato:"dinheiro"},
          {nome:"Status", bind:"statusDivida", url:"/app/buscar-divida/{iddivida}", formato: formatoStatusDivida },
          {nome:"Aprovado?", bind:"isAcordoAprovado", formato:"badge", url:"/app/financeiro/{iddivida}" }
        ]} />
    </div>)
  }

  function renderScreen() {
    if (isNew) {
      return renderAcordoScreen(true);
    }

    if (idDivida) {
      return renderSelectedDividaScreen();
    }

    return renderListScreen();
  }

  const colStyleForm = {paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold'};
  return (    
    <div className="content mw-full">
      { renderScreen() }
    </div>
  );
}

export default Financeiro;