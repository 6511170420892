import React, { useState, useEffect } from 'react';
import { useLocalStorage } from '../components/useLocalStorage';
import './Login.css';
import { login } from './Auth';
import Erro from '../components/Erro';
import { URL_BASE_SERVER } from './Auth';

function Login() {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  const tenant = subdomain === "localhost" ? "sec" : subdomain;

  const [usuario, setUsuario] = useLocalStorage('usuario', '');
  const [senha, setSenha] = useState('');
  const [lembrar, setLembrar] = useState(false);
  const [erro, setErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');

  useEffect(() => {
    setLembrar(usuario !== '');
  }, [usuario, erro]);

  function lembrarOuNao() {
    if (lembrar) {
      localStorage.setItem('usuario', usuario);
    } else {
      localStorage.setItem('usuario', '');
    }
  }

  const submitHandler = e => {
    e.preventDefault();
    lembrarOuNao();

    setErro(false);
    fetch(URL_BASE_SERVER + '/api/usuario/login', {
      method: "POST",
      body: JSON.stringify({ user: usuario, password: senha }),
      headers: { "Content-type": "application/json; charset=UTF-8", "tenant": tenant }
    })
      .then(function (response) {
        setErro(!response.ok);
        if (response.ok) {
          return response.json();
        }
        return { "erro": "O login não pode ser efetuado." }
      }).then(function (data) {
        if (data.usuario) {
          console.log(data.token)
          login(data.token, data.grupo, data.name);
          window.location.href = '/app';
        } else {
          setMensagemErro(data.erro);
        }
      });
  }

  return (
    <div id="principal">
      <div className="mw-full center">
        <div className="card form-inline w-400 mw-full">
          <a href="/" className="sidebar-brand d-flex justify-content-center">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logotipo sec" style={{ width: '20%', height: 'auto' }} />
            <h2>Cobrança&nbsp;&nbsp;</h2>
            <span style={{ paddingLeft: '8px' }}></span>
            <span className="badge badge-primary" style={{ marginTop: '-20px', marginLeft: '-15px' }} >Beta</span>
          </a>

          <Erro mostrarErro={erro} mensagemErro={mensagemErro} onClose={() => { setErro(!erro) }} />

          <form onSubmit={submitHandler} >
            <div className="form-group">
              <label className="required w-100" htmlFor="username">Usuário</label>
              <input type="text" className="form-control" placeholder="Usuário" id="username" required="required"
                onChange={e => { setUsuario(e.target.value); }} value={usuario} autoComplete="username" />
            </div>
            <div className="form-group">
              <label className="required w-100" htmlFor="password">Senha</label>
              <input type="password" className="form-control" placeholder="Senha" id="password" required="required"
                onChange={e => { setSenha(e.target.value); }} value={senha} autoComplete="current-password" />
            </div>

            <div className="form-group mb-0">
              <div className="custom-control">
                <div className="custom-switch">
                  <input type="checkbox" id="remember-me"
                    onChange={e => { setLembrar(e.target.value); }}
                    checked={lembrar} />
                  <label htmlFor="remember-me">Lembrar-me</label>
                </div>
              </div>
              <input type="submit" className="btn btn-primary ml-auto" value="Entrar" />
            </div>
          </form>
          {/* <div>
            <button className="btn btn-secondary" type="button"
              onClick={()=>{window.location.href='http://siscob.ddns.net:8085/'}} >Sistema anterior</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
