import React, { useState }  from 'react';
import moment from "moment";


function DateSelect(props) {
    const [dataSelecionada, setDataSelecionada] = useState(
      (props.value || moment().format((props.formato || "YYYY-MM-DD" )))
    );
    var onChangeFunction = props.onChange.bind(this);

    function changeDate(e) {
      setDataSelecionada(e.target.value);
      if(props.onChange !== undefined) {
        onChangeFunction(e.target.value);
      }
    }
    let classes = "form-control";
    if(props.tamanho === 'lg') {
      classes = classes + " form-control-lg";
    } if(props.tamanho === 'sm') {
      classes = classes + " form-control-sm";
    }

    return (
        <input type="date" id="start" name="trip-start"
                className={classes} 
                onChange={changeDate} value={dataSelecionada} />
    );
}

export default DateSelect;