import React from 'react';
import MenuLateral from './components/MenuLateral';
import MenuSuperior from './components/MenuSuperior';
import './App.css';

function App(props) {
  const Pagina = props.pagina;
  return (
    <div className="page-wrapper with-sidebar">
      <MenuLateral />

      <div className="content-wrapper with-navbar">
        <MenuSuperior />

        <Pagina />
      </div>
    </div>
  );
}

export default App;
