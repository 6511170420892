import TabelaPaginada from '../components/TabelaPaginada';
import { formatoStatusDivida } from '../components/Util';
import DateSelect from '../components/DateSelect';
import React, {useState} from 'react';
import moment from "moment";

function Agendamento(props) {
  const filtroInicial = "de="+moment().format("YYYY-MM-DD")+"&ate="+moment().format("YYYY-MM-DD");
  let [filtroData, setFiltroData] = useState(filtroInicial);

  let [cpfCnpjDevedor, setCpfCnpjDevedor] = useState('');
  
  function changeDataAgendamentos(value) {
    setFiltroData(montarFiltroData(value));
  }

  function montarFiltroData(data) {
    return "de="+data+"&ate="+data;
  }

  return (
    <div className="content">
      <h1 className="content-title">
        Agendamentos
      </h1>
      <div className="mw-full form-inline">
        <DateSelect onChange={changeDataAgendamentos} />
        <div className="col form-inline"></div>
        <form className="col form-inline" action="/app/buscar-divida" >
          <label htmlFor="q" style={{fontWeight: 'bold'}}>Devedor: </label>
          <input type="text" className="form-control" id="q" name="q" value={cpfCnpjDevedor}
            placeholder="CPF/CNPJ ou Nome" onChange={(e) => {setCpfCnpjDevedor(e.target.value)}} />
          <button className="btn btn-primary" type="submit">
            <i className="fas fa-search"></i>
          </button>
        </form>
      </div>
      <br />
      <TabelaPaginada apiNome="agendamentos" filtro={filtroData}
        colunas={[
          {nome:"Cliente",   bind:"divida.empresa.razaosocial" }, 
          {nome:"Devedor",   bind:"divida.devedor.nomerazao" }, 
          {nome:"Retorno",   bind:"dataretorno", formato:"dateUsToBr"}, 
          {nome:"Documento", bind:"divida.devedor.cpfcnpj", formato: "cpfOuCnpj"},
          {nome:"Usuário",   bind:"usuario.usuario"}, 
          {nome:"Status",    bind:"divida.statusDivida", url:"/app/buscar-divida/{divida.iddivida}", formato: formatoStatusDivida} 
        ]} />
    </div>
  );
}

export default Agendamento;