import React, {useState, useEffect} from 'react';
import api from '../auth/Api';
import { logout } from '../auth/Auth';
import { downloadCSV } from '../components/Util'

function TabelaPaginada(props) {
  let [linhas, setLinhas] = useState([]);
  let [totalDePaginas, setTotalDePaginas] = useState(0);
  let [paginaAtual, setPaginaAtual] = useState(0);

  const cssLink = {
    color: 'rgba(255,255,255,.95)',
    fontWeight: 'bold'
  };

  let quantidadePorPagina = props.quantidadePorPagina || 20;
  const alinhamentoPaginacaoHorizontal = props.alinhamentoPaginacaoHorizontal || "center";
  const alinhamentoPaginacaoVertical = props.alinhamentoPaginacaoVertical || "botton";
  const semPaginacao = props.semPaginacao || false;

  let tituloTag = [];
  let i = 0;
  (props.colunas || []).forEach(col => {
    tituloTag.push(<th key={"col-"+i}>{col.nome}</th>);
    i = i + 1;
  });

  function getBindValue(linha, bind) {
    const nivelDeAcesso = bind.split(".");
    let binded = linha;
    nivelDeAcesso.forEach(n => {
      binded = binded[n];
    });
    return binded;
  }

  function bindValuesInsideString(object, url) {
    const posicaoInicial = url.indexOf("{");
    if(posicaoInicial < 0){
      return url;
    }

    const posicaoFinal = url.indexOf("}");
    const urlAntesDaPosicaoInicial = url.substring(0, posicaoInicial);
    const urlDepoisDaPosicaoFinal = url.substring(posicaoFinal+1, url.length);
    const bind = url.substring(posicaoInicial+1, posicaoFinal);

    return urlAntesDaPosicaoInicial + getBindValue(object, bind) + bindValuesInsideString(object, urlDepoisDaPosicaoFinal);
  }

  function getBindValueWithUrl(linha, bind, url, formato, icone) {
    const urlBinded = bindValuesInsideString(linha, url);
    return (<a style={cssLink} href={urlBinded}>{getBindValueFormat(linha, bind, formato, icone)}</a>);
  }
  
  function copyToClipboard(valor) {
    navigator.clipboard.writeText(valor)
    window.halfmoon.initStickyAlert({
      content: "O conteúdo: <br><br><b>'"+valor+"'</b><br><br>Foi copiado para a área de transferência.",
      alertType: "alert",
      fillType: "filled-lm"
    });
  }
  
  function copyNumberToClipboard(valor) {
    navigator.clipboard.writeText(valor)
    window.halfmoon.initStickyAlert({
      content: "O número <b>'"+valor+"'</b> foi copiado para a área de transferência.",
      alertType: "alert",
      fillType: "filled-lm"
    });
  }

  function getBindValueFormat(item, bind, colFormato, icone) {
    let valor = getBindValue(item, bind);
    const formato = colFormato || "";

    if (valor === true || valor === false) {
      if (valor === true) {
        return (<span className="badge badge-success">Sim</span>); 
      }

      return (<span className="badge">Não</span>); 
    }
    
    if(typeof(formato) === 'function') {
      const funcao = formato.bind(this);
      const classes = funcao(valor);
      return (<span className={classes}>{valor}</span>);

    } else if(formato.includes("badge")) {
      const classesBadge = "badge "+formato;
      return (<span className={classesBadge}>{valor}</span>);

    } else if(formato === "money" || formato === "dinheiro") {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
    
    } else if(formato === "telefone") {
      let valorLimpo = valor.replace(/[^\d]/g, "");
      if (valorLimpo.length === 11) {
        return valorLimpo.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      } else if (valorLimpo.length === 10) {
        return valorLimpo.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      } else if (valorLimpo.length === 8) {
        // console.log(valorLimpo.replace(/(\d{4})(\d{4})/, "$1-$2"))
        return valorLimpo.replace(/(\d{4})(\d{4})/, "$1-$2");
      }
      return valor;

    } else if(formato === "cpfOuCnpj") {
      let valorLimpo = valor.replace(/[^\d]/g, "");
      if(valor.length === 11) {
        return valorLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else {
        return valorLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }

    } else if(formato === "iconPersonalizedCopy") {
      if(valor === null || valor === "")
        return ("");

      return (
        <button className="btn" onClick={() => {copyToClipboard(valor)}}
                  data-toggle="tooltip" data-title={valor} data-placement="top">
          <span className={icone}></span>
        </button>
      );

    } else if(formato === "phoneIconCopyButton") {
      if(valor === null || valor === "")
        return ("");
        
      return (
        <button className="btn" onClick={() => {copyNumberToClipboard(valor)}}
                  data-toggle="tooltip" data-title={valor} data-placement="top">
          <span className="fas fa-phone-square-alt"></span>
        </button>
      );
    } else if(formato === "dateUsToBr") {
      if (valor !== null) {
        return valor.substring(8, 10)+"/"+valor.substring(5, 7)+"/"+valor.substring(0, 4);
      }
      return "";

    } else if(formato === "buttonEdit") {
      return (<i className="fas fa-pencil-alt"></i>);

    } else if(formato === "selecaoSimples") {
      // eslint-disable-next-line
      if(valor === props.selecionado || valor == props.selecionado)
        return (<i className="far fa-check-square" style={{color:"#008B45"}}></i>);
      return null;
    }
    return valor; 
  }

  function criarLinha(item, pos, colunas) {
    let linha = {key: pos}
    let cols = [];
    let colPos = 0;
    colunas.forEach(col => {
      cols.push({key:"col-"+colPos, value: (
        col.url === undefined ? 
          getBindValueFormat(item, col.bind, col.formato, col.icone) : 
          getBindValueWithUrl(item, col.bind, col.url, col.formato, col.icone)
        ) 
      });
      colPos = colPos + 1;
    });
    linha.cols = cols;
    return linha;
  }

  function carregadosDadosTabela(dados) {
    if(props.enableConsoleLog) {
      console.log(dados);
    }

    setPaginaAtual(dados.paginaAtual);
    setTotalDePaginas(dados.totalDePaginas);
    // setTotalDeRegistros(dados.totalDeRegistros);

    let pos = 0;
    let ls = [];
    if (dados.itens) {
      dados.itens.forEach(item => {
        ls.push(criarLinha(item, pos, props.colunas));
        pos = pos + 1;
      });
      setLinhas(ls);
      return ls;
    }
  }

  function primeiraPagina(){
    setPaginaAtual(0);
  }

  function proximaPagina(){
    setPaginaAtual(paginaAtual+1);
  }

  function paginaAnterior(){
    setPaginaAtual(paginaAtual-1);
  }

  function ultimaPagina(){
    setPaginaAtual(totalDePaginas-1);
  }

  function convertObjectForCSV(data) {
    let objetos = [];
    data.itens.forEach((item) => {
      let obj = {};
      props.colunas.forEach((col => {
        obj[col.nome] = getBindValue(item, col.bind);
      }));
      objetos.push(obj);
    });

    downloadCSV(objetos);
  }

  function exportAllData() {
    const url = props.apiNome+'/?paginaAtual=0&quantidadePorPagina=9999&'+(props.filtro || "");
    api.get(url)
    .then((response) => convertObjectForCSV(response.data))
    .catch((err) => {
      console.log(err);
      if(err.response.status === 400) {
      } else if(err.response.status === 403) {
        logout();
        window.location.href='/';
        return;
      }
      // console.error("falhou: " + err);
    });
  }

  function paginacao(renderizar) {
    if(semPaginacao || !renderizar) {
      return (<span></span>);
    }

    return (
      <nav aria-label="Navegação">
        <ul className={"pagination text-"+alinhamentoPaginacaoHorizontal}>
          {/* <!-- Previous page --> */}
          <li className={"page-item"+(paginaAtual<=0?" disabled":"")}>
            <span onClick={paginaAnterior} className="page-link">
              <i className="fa fa-angle-left" aria-hidden="true"></i>
              <span className="sr-only">Previous</span>
            </span>
          </li>
          {/* <!-- Pages and ellipses --> */}
          {paginaAtual > 0 ? <li className="page-item"><span onClick={primeiraPagina} className="page-link">1</span></li> : ""}
          {paginaAtual > 2 ? <li className="page-item ellipsis"></li> : ""}
          {paginaAtual-1 > 0 ? <li className="page-item"><span onClick={paginaAnterior} className="page-link">{paginaAtual}</span></li> : ""}
          
          {/* <!-- Active page item --> */}
          <li className="page-item active" aria-current="page">
            <span className="page-link" tabIndex="-1">{paginaAtual+1}</span>
          </li>


          {paginaAtual+1 < totalDePaginas ? <li className="page-item"><span onClick={proximaPagina} className="page-link">{paginaAtual+2}</span></li> : ""}
          {paginaAtual+3 < totalDePaginas ? <li className="page-item ellipsis"></li> : ""}
          {paginaAtual+2 < totalDePaginas ? <li className="page-item"><span onClick={ultimaPagina} className="page-link">{totalDePaginas}</span></li> : ""}
          {/* <!-- Next page --> */}
          <li className={"page-item"+(paginaAtual+1===totalDePaginas?" disabled":"")}>
            <span onClick={proximaPagina} className="page-link">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
              <span className="sr-only">Next</span>
            </span>
          </li>
          {/* <!-- Exportar conteudo --> */}
          <li className="page-item">
            <span onClick={exportAllData} className="page-link">
              <i className="fas fa-file-export"></i> Exportar
            </span>
          </li>
        </ul>
      </nav>
    );
  }

  useEffect(() => {
    if(linhas !== undefined) {
      // const url = props.apiNome+'/?paginaAtual='+(paginaAtual || 0)+
      const url = props.apiNome+'/?paginaAtual='+(paginaAtual || 0)+
                  '&quantidadePorPagina='+quantidadePorPagina+
                  '&'+(props.filtro || "");
      api.get(url)
      .then((response) => carregadosDadosTabela(response.data))
      .catch((err) => {
        console.log(err);
        if(err.response.status === 400) {
        } else if(err.response.status === 403) {
          logout();
          window.location.href='/';
          return;
        }
        // console.error("falhou: " + err);
      });
    }
  },[props.apiNome, props.filtro, quantidadePorPagina, paginaAtual]);

  return (
    <div className="row mb-3">
      <div className="sticky-alerts"></div>
      {paginacao(alinhamentoPaginacaoVertical === "top")}
      <table className="table-responsive table table-striped">
        <thead>
          <tr>
            {tituloTag}
          </tr>
        </thead>
        <tbody>
          {
            (linhas || []).map(item => (
              <tr key={item.key}>
                {
                  (item.cols || []).map(col => (
                    <td key={col.key}>{col.value}</td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
      {paginacao(alinhamentoPaginacaoVertical === "botton")}
    </div>
  );
}

export default TabelaPaginada;
