import React from 'react';
import { logout } from '../auth/Auth';
import Breadcumb from './Breadcumb';
import { getName } from '../auth/Auth';

function MenuSuperior() {
  function toggleButton() {
    window.halfmoon.toggleSidebar();
  }

  function doLogout() {
    logout();
    window.location.href='/';
  }

  return (
    <div>
      <nav className="navbar justify-content-between">
        <div className="navbar-content">
          <button className="btn" type="button" onClick={toggleButton}>
            <i className="fa fa-bars" aria-hidden="true" ></i> 
          </button>
        </div>
        <div className="navbar-content">
          <span style={{paddingRight: '10px', fontWeight: 'bold'}}>{getName()}</span>
          <button className="btn" type="button" onClick={doLogout}>
          <i className="fas fa-sign-out-alt" aria-hidden="true"></i> Sair
          </button>
        </div>
      </nav>
      <Breadcumb />
    </div>
  );
}

export default MenuSuperior;
