import React from "react";
import Login from './auth/Login'
import App from './App';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAdminAuthenticated, isAuthenticated, isEmpresaAuthenticated } from "./auth/Auth";
import Agendamento from "./pagina/Agendamento";
import Dividas from "./pagina/Dividas";
import Devedores from "./pagina/Devedores";
import Empresas from "./pagina/Empresas";
import BuscarDivida from "./pagina/BuscarDivida";
import Importacao from "./pagina/Importacao";
import AcessoEmpresasClientes from "./pagina/AcessoEmpresarial/AcessoEmpresasClientes";
import Financeiro from "./pagina/Financeiro";
import RelatorioFinanceiro from "./pagina/RelatorioParcelasFinanceiro";

const PrivateRoute = ({ component: Component, pagina, isAdmin, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      ( isAuthenticated() && (!isAdmin || (isAdmin && isAdminAuthenticated() ) ) ) ? (
        <Component {...props} pagina={pagina} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const PaginaInicial = isEmpresaAuthenticated() ? AcessoEmpresasClientes : Agendamento;

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/app" component={App} pagina={PaginaInicial} isAdmin={false} />
      <PrivateRoute path="/app/buscar-divida" component={App} pagina={BuscarDivida} />
      <PrivateRoute path="/app/dividas" component={App} pagina={Dividas} isAdmin={true} />
      <PrivateRoute path="/app/devedores" component={App} pagina={Devedores} isAdmin={true} />
      <PrivateRoute path="/app/empresas" component={App} pagina={Empresas} isAdmin={true} />
      <PrivateRoute path="/app/importacao" component={App} pagina={Importacao} isAdmin={true} />
      <PrivateRoute path="/app/financeiro" component={App} pagina={Financeiro} isAdmin={true} />
      <PrivateRoute path="/app/relatorio-financeiro" component={App} pagina={RelatorioFinanceiro} isAdmin={true} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
