import React from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpresaAuthenticated } from '../auth/Auth';

function Breadcumb() {
  const path = useLocation().pathname;
  const paginas = path.substr(1).split("/");

  //Remove o ultimo item do array se for vazio
  if(paginas[paginas.length-1] === "") {
    paginas.splice(paginas.length-1, 1);
  }

  let rows = [];
  let pathOfBreadcumb = "";
  for(let i = 0; i < paginas.length; i++) {
    let pgNome = paginas[i];
    pathOfBreadcumb = pathOfBreadcumb + "/" + pgNome;
    if(pgNome === 'app') {
      pgNome = isEmpresaAuthenticated() ? 'painel' : 'início';
    }
    pgNome = pgNome.substr(0, 1).toUpperCase()+pgNome.substr(1);
    let pgAtual = i === paginas.length - 1;
    const classes = pgAtual ? "breadcrumb-item active" : "breadcrumb-item";
    const key = "breadcumb-"+i;

    rows.push(<li key={key} className={classes} aria-current={pgAtual?"page":""}><a href={pathOfBreadcumb}> {pgNome}</a></li>);
  }

  return (
    <nav aria-label="Breadcrumb navigation" style={{paddingLeft:"1rem", paddingTop:"1rem"}}>
      <ul className="breadcrumb">
        {rows}
      </ul>
    </nav>
  );
}

export default Breadcumb;
